import type { FormFieldType } from '@/types/form-field-type'

import Checkbox from './Checkbox'
import styles from './CheckboxGroup.module.scss'

type CheckboxGroupProps = {
  label?: React.ReactNode
  allOptionsLabel?: string
  disabled?: boolean
  options: { value: string; label: string; disabled?: boolean }[]
} & FormFieldType<string[]>

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { allOptionsLabel = 'All' } = props

  const isAllSelected = props.value?.length === props.options.length

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allValue = props.options.map(option => option.value)
      props.onChange?.(allValue)
    } else {
      props.onChange?.([])
    }
  }

  const handleChange = (checked: boolean, id: string) => {
    if (checked) {
      props.onChange?.([...(props.value || []), id])
    } else if (!checked) {
      const filteredValue = props.value?.filter(item => item !== id) || []
      props.onChange?.(filteredValue)
    }
  }

  const ariaControls = props.options
    .map((_, index) => `checkbox-item-${index}`)
    .join(' ')

  return (
    <fieldset role="group" className={styles.wrapper}>
      <legend className={styles.label}>{props.label}</legend>
      <Checkbox
        className={styles.allOption}
        label={allOptionsLabel}
        id="all"
        value={isAllSelected}
        checked={isAllSelected ? true : props.value?.length ? 'mixed' : false}
        ariaControls={ariaControls}
        describedby={props.describedby}
        invalid={props.invalid}
        required={props.required}
        disabled={props.disabled}
        onChange={handleSelectAll}
      />
      <ul className={styles.list}>
        {props.options.map((option, index) => (
          <li key={option.value}>
            <Checkbox
              className={styles.option}
              label={option.label}
              id={`checkbox-item-${index}`}
              disabled={option.disabled}
              value={props.value?.includes(option.value) || false}
              onChange={checked => handleChange(checked, option.value)}
            />
          </li>
        ))}
      </ul>
    </fieldset>
  )
}

export default CheckboxGroup
