import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import type { StringOption } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import { arrayOfOptions, requiredString } from '@/utils/zod'

const coursesResponseSchema = z.object({
  courses: arrayOfOptions
})

const groupsResponseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    course: z.object({
      id: requiredString(),
      name: requiredString()
    })
  })
)

export type ClassGradesFilters = {
  coursesOptions: StringOption[]
  groupsByCourseMap: Record<string, StringOption[]>
}

const parseResponse = (
  coursesResponse: z.infer<typeof coursesResponseSchema>,
  groupsResponse: z.infer<typeof groupsResponseSchema>
): ClassGradesFilters => ({
  coursesOptions: coursesResponse.courses.map(course => ({
    value: course.value,
    label: course.label
  })),

  groupsByCourseMap: groupsResponse.reduce<Record<string, StringOption[]>>(
    (map, element) => ({
      ...map,
      [element.course.id]: map[element.course.id]
        ? [
            ...map[element.course.id],
            { label: element.name, value: element.id }
          ]
        : [{ label: element.name, value: element.id }]
    }),
    {}
  )
})

type Params = {
  semesterId: string
  classId: string
}

const useClassGradesFilters = (params: Params) =>
  useQuery({
    queryKey: ['panelClassesGroupsOptionsGrades', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const [coursesFilters, groupsFilters] = await Promise.all([
        httpService.panel.panelClassesGroupsOptionsRetrieve(
          params.classId,
          params.semesterId
        ),
        httpService.panel.panelClassesGroupsList(
          params.classId,
          params.semesterId,
          {
            page_size: 'max',
            fetchKeys: {
              id: true,
              course: true,
              name: true
            }
          }
        )
      ])

      const parsed = parseResponse(
        coursesResponseSchema.parse({ courses: coursesFilters.courses }),
        groupsResponseSchema.parse(groupsFilters.results)
      )
      return parsed
    }
  })

export default useClassGradesFilters
