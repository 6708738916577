import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import DataBlock from '@/components/DataBlock/DataBlock'
import LinkText from '@/components/LinkText/LinkText'
import Loading from '@/components/Loading'
import { formatDate, getWeekDays } from '@/utils/format-date'

import styles from './UsersDetailsView.module.scss'
import useUser, { type Schedule } from '../queries/useUser'

const routeApi = getRouteApi('/_auth/users/$userId/details')

const UserDetailsView = () => {
  const { userId } = routeApi.useParams()
  const { data: user, isLoading } = useUser(userId)
  const { t } = useTranslation(['users'])

  const getScheduleText = (value?: Schedule[]) =>
    value?.map(item => `${item.timeFrom} - ${item.timeTo}`)

  const basicInformationInfo = [
    { label: t('label.gender'), value: user?.gender },
    { label: t('label.birth'), value: formatDate(user?.dateOfBirth) },
    { label: t('label.nationality'), value: user?.nationalities },
    { label: t('label.first-language'), value: user?.firstLanguage },
    { label: t('label.community'), value: user?.community },
    {
      label: t('label.ahv-number'),
      value: user?.ahvNumber
    }
  ]

  const academicInfo = [
    {
      label: t('label.class-tutor'),
      value: (
        <>
          {user?.tutor?.classes.map(classItem => (
            <LinkText
              size="medium"
              key={classItem.id}
              to="/students-and-classes/classes/$classId/details"
              params={{
                classId: classItem.id
              }}
            >
              {classItem.name}
            </LinkText>
          ))}
        </>
      ),
      hidden: !user?.profiles.includes('Tutor')
    },
    {
      label: t('label.preferred-room'),
      value: user?.preferredRoom?.name,
      hidden:
        !user?.profiles.includes('Tutor') && !user?.profiles.includes('Teacher')
    }
  ].filter(item => !item.hidden)

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: user?.phoneNumber },
    { label: t('label.email'), value: user?.email },
    { label: t('label.emergency-contact'), value: user?.emergencyPhoneNumber }
  ]

  const weekdays = getWeekDays()

  const scheduleInfo = [
    {
      label: weekdays[0],
      value: getScheduleText(user?.teacher?.customSchedule.monday)
    },
    {
      label: weekdays[1],
      value: getScheduleText(user?.teacher?.customSchedule.tuesday)
    },
    {
      label: weekdays[2],
      value: getScheduleText(user?.teacher?.customSchedule.wednesday)
    },
    {
      label: weekdays[3],
      value: getScheduleText(user?.teacher?.customSchedule.thurdsay)
    },
    {
      label: weekdays[4],
      value: getScheduleText(user?.teacher?.customSchedule.friday)
    },
    {
      label: weekdays[5],
      value: getScheduleText(user?.teacher?.customSchedule.saturday)
    },
    {
      label: weekdays[6],
      value: getScheduleText(user?.teacher?.customSchedule.sunday)
    }
  ]

  return (
    <Loading spinning={isLoading}>
      <div className={styles.wrapper}>
        <DataBlock
          header={t('header.basic-information')}
          infoArray={basicInformationInfo}
        />

        <DataBlock header={t('header.academics')} infoArray={academicInfo} />

        <DataBlock
          header={t('header.contact-details')}
          infoArray={contactDetailsInfo}
        />

        <DataBlock header={t('header.schedule')} infoArray={scheduleInfo} />
      </div>
    </Loading>
  )
}

export default UserDetailsView
