import Button, { type ButtonVariant } from '@/components/Button/Button'

import styles from './Actions.module.scss'
import ActionsDrawer from '../ActionsDrawer/ActionsDrawer'

export type Action = {
  icon?: React.ReactNode
  onClick: () => void
  variantButton?: ButtonVariant
  variantAction?: 'normal' | 'danger'
  loading?: boolean
  text: string
  hidden: boolean
}

type ActionsProps = {
  actions?: Action[]
}

const Actions = (props: ActionsProps) =>
  props.actions?.length ? (
    <>
      <div className={styles.buttonWrapper}>
        {props.actions?.map((action, index) => (
          <Button
            key={index}
            icon={action.icon}
            onClick={action.onClick}
            variant={action.variantButton || 'secondary'}
            loading={action.loading}
          >
            {action.text}
          </Button>
        ))}
      </div>

      <ActionsDrawer
        actions={props.actions.map(action => ({
          icon: action.icon,
          text: action.text,
          onClick: action.onClick,
          hidden: action.hidden,
          isDanger: action.variantAction === 'danger'
        }))}
        onlyMobile
        triggerButton={{ size: 'medium' }}
      />
    </>
  ) : null

export default Actions
