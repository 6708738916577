import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { AttendanceCheckData } from '../types'

const fieldErrorsMapper: FieldErrorsMapper<AttendanceCheckData> = {
  presence: 'presence'
}

const useAttendanceCheck = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: AttendanceCheckData) =>
      httpService.panel.panelAttendancesUpdate(data.id, {
        presence: data.presence || ''
      }),
    onSuccess: options?.onSuccess,
    fieldErrorsMapper
  })

export default useAttendanceCheck
