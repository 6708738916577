import { addDays, format, startOfWeek } from 'date-fns'
import { de, enGB } from 'date-fns/locale'

import i18n from '@/i18n'

import {
  API_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT
} from '../constants/date-format'

export const getDateFnsLocale = () => (i18n.language === 'de' ? de : enGB)

export const getWeekDays = () => {
  const weekStart = startOfWeek(new Date(), { locale: getDateFnsLocale() })
  const days = []

  for (let i = 0; i < 7; i++) {
    days.push(
      format(addDays(weekStart, i), 'EEEE', { locale: getDateFnsLocale() })
    )
  }

  return days
}

export const formatDate = (date?: Date) =>
  date ? format(date, DATE_FORMAT) : ''

export const formatDateTime = (date?: Date) =>
  date ? format(date, DATE_TIME_FORMAT) : ''

export const formatApiDate = (date?: Date) =>
  date ? format(date, API_DATE_FORMAT) : ''

export const formatWeek = (date?: Date) =>
  date
    ? format(date, `'${i18n.t('text.week', { ns: 'common' })}' I (yyyy)`)
    : ''

export const formatFullMonthName = (date?: Date) =>
  date ? format(date, 'MMMM', { locale: getDateFnsLocale() }) : ''

export const formatShortWeekdayName = (date?: Date) =>
  date ? format(date, 'EEE', { locale: getDateFnsLocale() }) : ''

export const formatDay = (date?: Date) =>
  date ? format(date, 'd', { locale: getDateFnsLocale() }) : ''

export const formatTime = (date?: Date) =>
  date ? format(date, TIME_FORMAT) : ''
