import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import useExternalErrors from '@/hooks/useExternalErrors'
import i18n from '@/i18n'
import type { FormError } from '@/types/form-error'
import { requiredString } from '@/utils/zod'

import styles from './LoginForm.module.scss'
import PasswordRequirements from './PasswordRequirements'
import { isPasswordCorrect } from '../utils/password-requirements'

const formSchema = () =>
  z
    .object({
      repeatPassword: requiredString(),
      password: requiredString().refine(value => isPasswordCorrect(value), {
        message: i18n.t('error.password-too-week', { ns: 'auth' })
      })
    })
    .refine(data => data.password === data.repeatPassword, {
      path: ['repeatPassword'],
      message: i18n.t('error.passwords-not-match', { ns: 'auth' })
    })

export type NewPasswordPayload = z.infer<ReturnType<typeof formSchema>>

type NewPasswordFormProps = {
  onSubmit: (payload: NewPasswordPayload) => void
  loading?: boolean
  errors?: FormError<NewPasswordPayload>[]
}

const NewPasswordForm = (props: NewPasswordFormProps) => {
  const { t } = useTranslation(['auth'])

  const form = useForm<NewPasswordPayload>({
    resolver: zodResolver(formSchema()),
    mode: 'all',
    defaultValues: {
      password: '',
      repeatPassword: ''
    }
  })

  const password = form.watch('password')

  useExternalErrors(props.errors, form)

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <FormField
          control={form.control}
          id="password"
          required
          label={t('label.password')}
          name="password"
          render={({ inputProps }) => (
            <Input
              placeholder={t('help.type-new-password')}
              {...inputProps}
              type="password"
              aria-describedby="requirements"
              onChange={value => {
                inputProps.onChange?.(value)
                form.trigger('repeatPassword')
              }}
            />
          )}
        />
        <FormField
          control={form.control}
          id="repeatPassword"
          required
          label={t('label.repeat-password')}
          name="repeatPassword"
          render={({ inputProps }) => (
            <Input
              placeholder={t('help.repeat-password')}
              {...inputProps}
              type="password"
            />
          )}
        />

        <PasswordRequirements id="requirements" password={password} />

        <Button type="submit" block className={styles.loginButton}>
          {t('button.set-password')}
        </Button>
      </form>
    </FormProvider>
  )
}

export default NewPasswordForm
