import { endOfISOWeek, format, startOfISOWeek } from 'date-fns'
import { z } from 'zod'

import { API_DATE_FORMAT } from '@/constants/date-format'

export const timetableFiltersSchema = z.object({
  dateFrom: z
    .string()
    .catch(format(startOfISOWeek(new Date()), API_DATE_FORMAT)),
  dateTo: z.string().catch(format(endOfISOWeek(new Date()), API_DATE_FORMAT)),
  student: z.string().optional(),
  teacher: z.string().optional(),
  course: z.string().optional(),
  classId: z.string().optional(),
  room: z.string().optional()
})

export type TimetableFiltersKey = keyof z.infer<typeof timetableFiltersSchema>
