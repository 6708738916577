import { useQuery } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString, teacherStatus } from '@/utils/zod'

import { type DetailedTeacherAvailability } from '../types'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString(),
  status: teacherStatus,
  lessons: z.array(
    z.object({
      id: requiredString(),
      course: z.object({
        id: requiredString(),
        name: requiredString()
      }),
      group: z.object({
        id: requiredString(),
        name: requiredString()
      })
    })
  )
})

const parseResponse = (
  response: z.infer<typeof responseSchema>
): DetailedTeacherAvailability => ({
  id: response.id,
  name: response.name,
  status: response.status,
  lessons: response.lessons
})

const useTeacherAvailability = (params: {
  startDate: Date
  endDate: Date
  teacherId?: string
  enabled: boolean
}) =>
  useQuery({
    queryKey: ['panelTeachersAvailabilityRetrieve', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!params.teacherId) return

      const response =
        await httpService.panel.panelTeachersAvailabilityRetrieve(
          formatISO(params.endDate),
          formatISO(params.startDate),
          params.teacherId
        )

      return parseResponse(responseSchema.parse(response))
    },
    enabled: !!params.teacherId || !!params.enabled
  })

export default useTeacherAvailability
