import { type PanelAssignmentDelete } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { RemoveAssignmentPayload } from '../constants/assignment-payload'

const fieldErrorsMapper: FieldErrorsMapper<RemoveAssignmentPayload> = {
  id: 'id',
  non_field_error: 'root'
}

const useRemoveAssignment = (options?: {
  onSuccess: (data?: PanelAssignmentDelete) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: RemoveAssignmentPayload) =>
      httpService.panel.panelAssignmentsDestroy(data.id),
    onSuccess: data => options?.onSuccess?.(data),
    fieldErrorsMapper
  })

export default useRemoveAssignment
