import {
  Outlet,
  createFileRoute,
  redirect,
  useRouter
} from '@tanstack/react-router'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import i18n from '@/i18n'
import { StudentsAndClassesView } from '@/modules/studentsAndClasses'

export const Route = createFileRoute('/_auth/students-and-classes')({
  component: () => <StudentsAndClassess />,
  beforeLoad: () => {
    if (location.pathname === '/students-and-classes') {
      throw redirect({
        replace: true,
        to: '/students-and-classes/students',
        search: { pageSize: DEFAULT_PAGE_SIZE, page: 1 }
      })
    }
  },
  meta: () => [
    { title: i18n.t('navigation.students-and-classes', { ns: 'common' }) }
  ]
})

const StudentsAndClassess = () => {
  const { state } = useRouter()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Type instantiation is excessively deep and possibly infinite.
  const pathname = state.location.pathname

  if (
    pathname === '/students-and-classes/students' ||
    pathname === '/students-and-classes/classes'
  )
    return (
      <StudentsAndClassesView>
        <Outlet />
      </StudentsAndClassesView>
    )

  return <Outlet />
}
