import { createFileRoute } from '@tanstack/react-router'

import { UserView, userQueryOptions } from '@/modules/users'

export const Route = createFileRoute('/_auth/users/$userId')({
  component: UserView,
  loader: async ({ context, params, navigate, location }) => {
    const userDetails = await context.queryClient.ensureQueryData(
      userQueryOptions(params.userId)
    )

    if (location.pathname === `/users/${params.userId}`) {
      navigate({
        to: '/users/$userId/details',
        params
      })
    }
    return userDetails.name
  },
  meta: ({ loaderData }) => [{ title: loaderData }]
})
