import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useAllUsersCount = () =>
  useQuery({
    queryKey: ['useAllUsersCount'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const userList = await httpService.panel.panelUsersList({
        page: 1,
        page_size: 'max',
        fetchKeys: {
          id: true
        }
      })

      return userList.count
    }
  })

export default useAllUsersCount
