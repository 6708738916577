import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { TopicAndNotesFormPayload } from '../constants/lesson-payload'

type LessonUpdatePayload = TopicAndNotesFormPayload & {
  id: string
}

const fieldErrorsMapper: FieldErrorsMapper<TopicAndNotesFormPayload> = {
  topic: 'topic',
  notes: 'notes',
  non_field_error: 'root'
}

const useUpdateLesson = (options?: {
  onSuccess: (validData: TopicAndNotesFormPayload) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: LessonUpdatePayload) =>
      httpService.panel.panelLessonsUpdate(data.id, {
        topic: data.topic,
        notes: data.notes
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    fieldErrorsMapper
  })

export default useUpdateLesson
