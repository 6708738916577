import { useTranslation } from 'react-i18next'

import ArrowRightIcon from '@/assets/icons/arrow-right.svg?react'

import styles from './GradebookButton.module.scss'
import Button from '../Button/Button'

type GradebookButtonProps = {
  courseId: string
  groupId: string
  semesterId?: string
}
const GradebookButton = (props: GradebookButtonProps) => {
  const { t } = useTranslation(['common'])

  return (
    <div className={styles.buttonWrapper}>
      <Button
        size="small"
        variant="secondary"
        className={styles.button}
        asLink
        to="/timetable/$courseId/$groupId/grades"
        params={{
          courseId: props.courseId,
          groupId: props.groupId
        }}
        search={{
          semesterId: props.semesterId
        }}
      >
        <span className={styles.buttonText}>{t('button.go-to-gradebook')}</span>
        <ArrowRightIcon />
      </Button>
    </div>
  )
}

export default GradebookButton
