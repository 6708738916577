import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import LessonCancelIcon from '@/assets/icons/lesson-cancel.svg?react'
import LessonCoverIcon from '@/assets/icons/lesson-cover.svg?react'
import Tooltip from '@/components/Tooltip/Tooltip'
import { formatDate, formatTime } from '@/utils/format-date'
import { joinArray } from '@/utils/join-array'

import styles from './LessonEvent.module.scss'

export type LessonEventClickType = {
  lessonId: string
  courseId: string
  groupId: string | null
  isPreview?: boolean
}

export type LessonEventProps = {
  id: string
  startDate: Date
  endDate: Date
  teacherCoverName?: string
  coTeacherCoverName?: string
  coTeacherName?: string
  course: {
    id: string
    name: string
  }
  groupId?: string
  teacherName: string
  classNamesText: string
  roomName?: string
  isCancelled: boolean
  isPreview?: boolean
  clickable?: boolean
  onClick?: (params: LessonEventClickType) => void
}

const LessonEvent = (props: LessonEventProps) => {
  const { t } = useTranslation('timetable')

  const timeText = formatTime(props.startDate) + '-' + formatTime(props.endDate)
  const isLessonCovered = !!props.teacherCoverName || !!props.coTeacherCoverName
  const isLessonCancelled = !!props.isCancelled

  const handleClick = () => {
    if (props.clickable) {
      props.onClick?.({
        lessonId: props.id,
        courseId: props.course.id,
        groupId: props.groupId || null,
        isPreview: props.isPreview
      })
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx(
        styles.event,
        isLessonCovered && styles.eventCover,
        props.isPreview && styles.eventPreview,
        props.isCancelled && styles.lessonCancelled,
        props.clickable && styles.clickable
      )}
      onKeyDown={event => {
        if (event.key === 'Enter') handleClick()
      }}
      onClick={handleClick}
    >
      <VisuallyHidden>{formatDate(props.startDate)}</VisuallyHidden>
      <div className={styles.eventHeader} data-test-id="class-name">
        <span className={styles.eventCourse}>{props.course.name}</span>
        {isLessonCovered ? (
          <Tooltip
            text={t('help.covered-lesson')}
            trigger={<LessonCoverIcon className={styles.coverIcon} />}
          />
        ) : isLessonCancelled ? (
          <Tooltip
            text={t('help.cancelled-lesson')}
            trigger={<LessonCancelIcon className={styles.cancelIcon} />}
          />
        ) : null}
      </div>
      <span data-test-id="lesson-info">
        <div>
          <VisuallyHidden>{t('label.teacher')}</VisuallyHidden>
          <span className={styles.eventTeacher}>{props.teacherName}</span>
        </div>

        <div className={styles.coTeacher}>
          <span className={styles.eventText}>
            {props.coTeacherName ? `${t('label.co-teacher')}: ` : null}
            {props.coTeacherName}
          </span>
        </div>

        <span className={styles.eventText}>
          {`${t('text.class')}: ${props.classNamesText}`}
        </span>
        <span className={styles.eventText}>
          {`${t('label.time')}: `}
          {timeText}
        </span>
        <span className={styles.eventText}>
          {`${t('text.room')}: `}
          <span data-test-id="room-info">{props.roomName}</span>
        </span>
      </span>

      {isLessonCovered ? (
        <span className={styles.eventText}>
          {`${t('text.cover-for')}: ${joinArray([props.teacherCoverName, props.coTeacherCoverName])}`}
        </span>
      ) : null}
    </div>
  )
}
export default LessonEvent
