import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString(),
  students: z.array(
    z.object({
      id: requiredString(),
      name: requiredString(),
      first_name: requiredString(),
      last_name: requiredString(),
      student_class: z
        .object({
          id: requiredString(),
          name: requiredString()
        })
        .nullable(),
      attendance_percentage: z.number(),
      behaviour_grade: z.string().nullable(),
      proposed_grade: z.string().nullable(),
      final_grade: z.string().nullable(),
      grades: z.array(z.string())
    })
  )
})

export type CourseGroupStudent = {
  id: string
  name: string
  studentClass?: {
    id: string
    name: string
  }
  attendancePercentage: number
  behaviourGrade: string | null
  proposedGrade: string | null
  finalGrade: string | null
  grades: string[]
}

export type CourseGroup = {
  id: string
  name: string
  students: CourseGroupStudent[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): CourseGroup => ({
  id: response.id,
  name: response.name,
  students: response.students.map(student => ({
    id: student.id,
    name: student.name,
    studentClass: student.student_class
      ? {
          id: student.student_class.id,
          name: student.student_class.name
        }
      : undefined,
    attendancePercentage: student.attendance_percentage,
    behaviourGrade: student.behaviour_grade,
    proposedGrade: student.proposed_grade,
    finalGrade: student.final_grade,
    grades: student.grades
  }))
})

const useCourseGroup = (id: string, semesterId?: string) =>
  useQuery({
    queryKey: ['panelLessonsCourseGroupsRetrieve', id, semesterId],
    staleTime: 60 * 100,
    queryFn: async () => {
      const groupResponse = await httpService.panel.panelLessonsGroupsRetrieve(
        id,
        {
          semester_id: semesterId,
          fetchKeys: {
            id: true,
            name: true,
            students: true
          }
        }
      )

      const group = parseResponse(responseSchema.parse(groupResponse))

      return group
    }
  })

export default useCourseGroup
