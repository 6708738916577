import {
  Outlet,
  createFileRoute,
  redirect,
  useRouter
} from '@tanstack/react-router'

import i18n from '@/i18n'
import { CoursesView, getRoutesWithPermission } from '@/modules/courses'

export const Route = createFileRoute('/_auth/courses')({
  component: () => <Courses />,
  beforeLoad: ({ context }) => {
    if (location.pathname === '/courses' && context.authStore?.user) {
      const routes = getRoutesWithPermission(context.authStore?.user)

      if (routes[0]?.link.to) {
        throw redirect({
          replace: true,
          to: routes[0].link.to
        })
      }
    }
  },
  meta: () => [{ title: i18n.t('navigation.courses', { ns: 'common' }) }]
})

const Courses = () => {
  const { state } = useRouter()

  const coursesPaths = [
    '/courses/all-courses',
    '/courses/my-class-courses',
    '/courses/my-courses'
  ]

  if (coursesPaths.includes(state.location.pathname))
    return (
      <CoursesView>
        <Outlet />
      </CoursesView>
    )

  return <Outlet />
}
