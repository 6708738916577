import { MatchRoute, Outlet, createFileRoute } from '@tanstack/react-router'

import { StudentsView, studentsFiltersSchema } from '@/modules/students'

export const Route = createFileRoute('/_auth/students-and-classes/students')({
  validateSearch: search => studentsFiltersSchema.parse(search),
  component: () => (
    <>
      <MatchRoute to="/students-and-classes/students">
        <StudentsView />
      </MatchRoute>
      <Outlet />
    </>
  )
})
