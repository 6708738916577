import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { EditGradesColumnPayload } from '../constants/grades-column-paylod'

const fieldErrorsMapper: FieldErrorsMapper<
  Pick<EditGradesColumnPayload, 'name'>
> = {
  name: 'name',
  non_field_error: 'root'
}

const useEditGradeColumn = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: EditGradesColumnPayload) =>
      httpService.panel.panelGradesPartialUpdate(data.id, { name: data.name }),
    fieldErrorsMapper,
    onSuccess
  })

export default useEditGradeColumn
