import { Link, type LinkProps } from '@tanstack/react-router'
import clsx from 'clsx'
import React from 'react'

import type { routeTree } from '@/routeTree.gen'

import styles from './ButtonIcon.module.scss'

export type ButtonIconSize = 'extra-small' | 'small' | 'medium' | 'large'

type ButtonIconCommonProps = {
  size: ButtonIconSize
  variant: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger'
  className?: string
}

type ButtonIconLinkProps = LinkProps<typeof routeTree>

type ButtonIconButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onPointerDown?: () => void
  children: React.ReactNode
  dataTestId?: string
  disabled?: boolean
  ariaLabel?: string
  ariaChecked?: boolean
  ariaControls?: string
  role?: 'button' | 'switch'
}

type ButtonIconProps = ButtonIconCommonProps &
  (
    | ({
        asLink?: false
      } & ButtonIconButtonProps)
    | ({
        asLink: true
      } & ButtonIconLinkProps)
  )

const ButtonIcon = (
  props: ButtonIconProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const { size, variant, className, ...buttonProps } = props

  const buttonClassName = clsx(
    styles.buttonIcon,
    styles[variant],
    styles[size],
    className
  )

  return buttonProps.asLink ? (
    <Link {...buttonProps} className={buttonClassName}>
      {buttonProps.children}
    </Link>
  ) : (
    <button
      ref={ref}
      className={buttonClassName}
      type="button"
      aria-controls={buttonProps.ariaControls}
      role={buttonProps.role || 'button'}
      onClick={buttonProps.onClick}
      onPointerDown={buttonProps.onPointerDown}
      disabled={buttonProps.disabled}
      data-test-id={buttonProps.dataTestId}
      aria-checked={buttonProps.ariaChecked}
      aria-label={buttonProps.ariaLabel}
    >
      {buttonProps.children}
    </button>
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonIconProps>(ButtonIcon)
