import { z } from 'zod'

import type { PanelSendActivationLinkResponse } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

export const activateLinkFormSchema = z.object({
  ids: z.array(z.string()),
  sendToStudents: z.boolean(),
  sendToParents: z.boolean(),
  sendMode: z
    .union([
      z.literal('studentsAndParents'),
      z.literal('students'),
      z.literal('parents')
    ])
    .optional()
})

export type ActivateLinkFormPaylod = z.infer<typeof activateLinkFormSchema>

const responseSchema = z.object({
  successfully_sent: z.number(),
  unsuccessfully_sent: z.number()
})

export type ActivateLinkResponse = {
  successfullySent: number
  unsuccessfullySent: number
}

const parseResponse = (
  response: PanelSendActivationLinkResponse
): ActivateLinkResponse => {
  const parsed = responseSchema.parse(response)

  return {
    successfullySent: parsed.successfully_sent,
    unsuccessfullySent: parsed.unsuccessfully_sent
  }
}

const useSendActivationLink = ({
  onSuccess
}: {
  onSuccess: (data?: ActivateLinkResponse) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: Omit<ActivateLinkFormPaylod, 'sendMode'>) => {
      const response =
        await httpService.panel.panelStudentsSendActivationLinkCreate({
          ids: data.ids,
          send_to_parents: data.sendToParents,
          send_to_students: data.sendToStudents
        })

      return parseResponse(response)
    },
    onSuccess
  })

export default useSendActivationLink
