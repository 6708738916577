import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { addUnassignedFilterOption } from '@/utils/add-unassigned-filter-option'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    full_name: z.string().min(1)
  })
)

type Parent = {
  id: string
  name: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Parent[] =>
  response.map(item => ({
    id: item.id,
    name: item.full_name
  }))

const useParents = () =>
  useQuery({
    queryKey: ['panelParentsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelParentsList({
        fetchKeys: {
          id: true,
          full_name: true
        },
        page_size: 'max'
      })
      const parsedParents = parseResponse(
        responseSchema.parse(response.results)
      )
      const options = parsedParents.map(option => ({
        label: option.name,
        value: option.id
      }))

      return {
        options,
        optionsWithUnassigned: addUnassignedFilterOption(options)
      }
    }
  })

export default useParents
