import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import LinkText from '@/components/LinkText/LinkText'
import Loading from '@/components/Loading'
import { type CourseGroupStudent } from '@/queries/useCourseGroup'

import styles from './CourseRowDetails.module.scss'
import DataBlock from '../DataBlock/DataBlock'
import TextWithLabel from '../TextWithLabel/TextWithLabel'

type CourseRowDetailsProps = {
  withClassColumn?: boolean
  isLoading?: boolean
  students: CourseGroupStudent[]
}

const CourseRowDetails = (props: CourseRowDetailsProps) => {
  const { t } = useTranslation('common')

  return (
    <Loading spinning={props.isLoading}>
      <div
        className={clsx(
          styles.container,
          props.withClassColumn && styles.containerWithClasses
        )}
      >
        <DataBlock
          header={t('label.students')}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <TextWithLabel
                text={
                  <LinkText
                    size="medium"
                    to="/students-and-classes/students/$studentId/details"
                    params={{
                      studentId: student.id
                    }}
                  >
                    {student.name}
                  </LinkText>
                }
                variant="horizontal-full-row"
              />
            )
          }))}
        />

        {props.withClassColumn ? (
          <DataBlock
            header={t('label.class')}
            headerVariant="small"
            infoArray={props.students.map(student => ({
              value: student.studentClass ? (
                <TextWithLabel
                  text={
                    <LinkText
                      size="medium"
                      to="/students-and-classes/classes/$classId/details"
                      params={{
                        classId: student.studentClass.id
                      }}
                    >
                      {student.studentClass.name}
                    </LinkText>
                  }
                  variant="horizontal-full-row"
                />
              ) : null
            }))}
          />
        ) : null}

        <DataBlock
          header={t('label.grades')}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <TextWithLabel
                text={student.grades.join('  ')}
                variant="horizontal-full-row"
                size="small"
              />
            )
          }))}
        />
        <DataBlock
          header={t('label.attendance')}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <TextWithLabel
                text={`${Math.round(student.attendancePercentage * 100)}%`}
                variant="horizontal-full-row"
                size="small"
              />
            )
          }))}
        />

        <DataBlock
          header={t('label.behaviour-grade')}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <TextWithLabel
                text={student.behaviourGrade}
                variant="horizontal-full-row"
                size="small"
              />
            )
          }))}
        />

        <DataBlock
          header={t('label.proposed-grade')}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <TextWithLabel
                text={student.proposedGrade}
                variant="horizontal-full-row"
                size="small"
              />
            )
          }))}
        />

        <DataBlock
          header={t('label.final-grade')}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <TextWithLabel
                text={student.finalGrade}
                variant="horizontal-full-row"
                size="small"
              />
            )
          }))}
        />
      </div>
    </Loading>
  )
}

export default CourseRowDetails
