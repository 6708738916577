import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

import {
  parseResponse,
  responseSchema,
  type Params
} from '../constants/timetable-events'

const useTimetablePreviewEvents = (params: Params, enabled: boolean) =>
  useQuery({
    queryKey: ['panelTimetablePreviewList', params],
    queryFn: async () => {
      const response = await httpService.panel.panelTimetablePreviewList({
        class_id: params.classId,
        course_id: params.course,
        date_from: params.dateFrom,
        date_to: params.dateTo,
        room_id: params.room,
        student_id: params.student,
        teacher_id: params.teacher
      })

      return parseResponse(responseSchema.parse(response))
    },
    enabled
  })

export default useTimetablePreviewEvents
