import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import Select, { type SelectOption } from '@/components/Select/Select'
import { noneOption } from '@/constants/none-option'
import useExternalErrors from '@/hooks/useExternalErrors'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'

import styles from './GradeSelectCell.module.scss'
import {
  type GradeItemPayload,
  gradeItemForm
} from '../constants/grade-item-payload'
import useEditGradeItem from '../mutations/useEditGradeItem'

type GradeSelectCellProps = {
  gradeItemId: string
  dataTestId: string
  gradesOptions: SelectOption<string>[]
  onGradeChange: () => void
  color: 'light' | 'dark'
  value: string | null
}

const GradeSelectCell = (props: GradeSelectCellProps) => {
  const { t } = useTranslation(['lessonDetails'])
  const [isOpen, setIsOpen] = useState(false)
  const { isMobile } = useScreenResolution()

  const { mutate, isPending, formErrors } = useEditGradeItem({
    onSuccess: variables => {
      if (!props.value && !!variables.grade)
        toast({ variant: 'success', title: t('toast.grade-added') })
      else if (!!props.value && !variables.grade)
        toast({ variant: 'success', title: t('toast.grade-removed') })
      else toast({ variant: 'success', title: t('toast.grade-changed') })
      props.onGradeChange()
    }
  })

  const selectOptions = [noneOption(), ...props.gradesOptions]

  const selectedValue = props.gradesOptions.find(
    option => option.value === props.value
  )?.label

  const form = useForm<GradeItemPayload>({
    resolver: zodResolver(gradeItemForm()),
    mode: 'all',
    defaultValues: {
      grade: props.value || ''
    }
  })

  useExternalErrors(formErrors, form)

  useEffect(() => {
    form.reset()
  }, [form, isOpen])

  const handleOnSubmit = (payload: GradeItemPayload) => {
    mutate({
      id: props.gradeItemId,
      grade: payload.grade === 'none' ? null : payload.grade
    })
  }

  return isMobile ? (
    <ModalForm
      id="change-grade"
      title={t('header.add-grade')}
      size="xs"
      form={form}
      open={isOpen}
      onOpenChange={setIsOpen}
      submitText={t('button.save')}
      trigger={
        <Button variant="tertiary" block>
          {selectedValue}
        </Button>
      }
      onSubmit={handleOnSubmit}
      onCancel={() => setIsOpen(false)}
      loading={isPending}
      onClose={() => setIsOpen(false)}
    >
      <FormField
        control={form.control}
        id="grade"
        name="grade"
        label={t('label.grade')}
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            value={inputProps.value || ''}
            onBlur={value => {
              if (value) inputProps.onBlur?.(value === 'none' ? null : value)
            }}
            onChange={value => {
              if (value) inputProps.onChange?.(value === 'none' ? null : value)
            }}
            options={selectOptions}
            placeholder={t('help.select-grade')}
          />
        )}
      />
    </ModalForm>
  ) : (
    <Select
      className={(styles.gradeSelect, styles[props.color])}
      hideChevron
      value={props.value || ''}
      mountedInBody
      borderless
      dataTestId={props.dataTestId}
      id={props.gradeItemId}
      options={selectOptions}
      placeholder=""
      loading={isPending}
      onChange={value => {
        if (value) handleOnSubmit({ grade: value })
      }}
    />
  )
}

export default GradeSelectCell
