import { type TagColor } from '@/components/Tag/Tag'
import i18n from '@/i18n'

export const getColorByProfile = (value: string): TagColor => {
  switch (value) {
    case 'Tutor':
      return 'blue'
    case 'Teacher':
      return 'orange'
    case 'Admin':
      return 'violet'
    default:
      return 'gray'
  }
}

export const getLabelByProfile = (value: string): string => {
  switch (value) {
    case 'Tutor':
      return i18n.t('text.tutor', { ns: 'users' })
    case 'Teacher':
      return i18n.t('text.teacher', { ns: 'users' })
    case 'Admin':
      return i18n.t('text.admin', { ns: 'users' })
    default:
      return value
  }
}
