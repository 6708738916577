import { getRouteApi } from '@tanstack/react-router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { type Route } from '@/components/Tabs/Tabs'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import useSemesters from '@/queries/useSemesters'
import useAuthStore from '@/store/useAuthStore'
import type { RouteWithHasPermission } from '@/types/route'

import useClass from '../queries/useClass'

const routeApi = getRouteApi('/_auth/students-and-classes/classes/$classId')

const ClassView = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['classes'])

  const { classId } = routeApi.useParams()

  const { data: semesters } = useSemesters()

  const { data: classData } = useClass(classId)

  const { user } = useAuthStore()

  const isAdminOrClassTutor =
    !!user?.isSuperAdmin || user?.id === classData?.tutor.id

  const routes: RouteWithHasPermission[] = [
    {
      title: t('navigation.class-details'),
      link: {
        to: '/students-and-classes/classes/$classId/details'
      },
      hasPermission: true
    },
    {
      title: t('navigation.courses-and-groups'),
      link: {
        to: '/students-and-classes/classes/$classId/courses-and-groups',
        search: {
          semester: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission: isAdminOrClassTutor
    },
    {
      title: t('navigation.grades'),
      link: {
        to: '/students-and-classes/classes/$classId/grades',
        search: {
          semesterId: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission: isAdminOrClassTutor
    }
  ]

  const routesWithPermission: Route[] = routes
    .filter(route => route.hasPermission)
    .map(route => ({
      title: route.title,
      link: route.link
    }))

  return (
    <BasicLayout
      header={classData?.name}
      routes={routesWithPermission}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      {children}
    </BasicLayout>
  )
}
export default ClassView
