import clsx from 'clsx'
import { composeRef } from 'rc-util/lib/ref'
import * as React from 'react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EyeNone from '@/assets/icons/eye-none.svg?react'
import EyeOpen from '@/assets/icons/eye-open.svg?react'
import Search from '@/assets/icons/search.svg?react'
import useRemoveValueFromPasswordInput from '@/hooks/useRemoveValueFromPasswordInput'

import styles from './Input.module.scss'
import type { FormFieldType } from '../../types/form-field-type'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

export type InputProps = {
  type?: 'text' | 'password' | 'tel' | 'email' | 'search'
  className?: string
  placeholder?: string
  defaultValue?: string
  dataTestId?: string
  disabled?: boolean
  borderless?: boolean
  onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: () => void
} & FormFieldType<string>

const Input = (
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const { t } = useTranslation(['common'])

  const [showPassword, setShowPassword] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const removeValue = useRemoveValueFromPasswordInput(inputRef)
  removeValue()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    removeValue()
    props.onChange?.(event.target.value)
    props.onChangeEvent?.(event)
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    removeValue()
    props.onBlur?.(event.target.value)
  }

  const handleFocus = () => {
    removeValue()
    props.onFocus?.()
  }

  const inputType =
    props.type === 'password' && !!showPassword ? 'text' : props.type

  return (
    <div
      className={clsx(
        styles.inputWrapper,
        props.borderless && styles.borderless
      )}
    >
      <input
        ref={composeRef(ref, inputRef)}
        data-test-id={props.dataTestId}
        type={inputType}
        className={clsx(
          styles.input,
          props.className,
          props.borderless && styles.borderless
        )}
        disabled={props.disabled}
        id={props.id}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value || ''}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        aria-describedby={props.describedby}
        aria-invalid={props.invalid}
        aria-required={props.required}
        aria-disabled={props.disabled}
      />
      {props.type === 'password' ? (
        <div className={styles.iconContainer}>
          <ButtonIcon
            role="switch"
            ariaChecked={showPassword}
            onClick={() => {
              setShowPassword(!showPassword)
            }}
            disabled={props.disabled}
            size="small"
            variant="tertiary"
            ariaLabel={
              showPassword ? t('label.hide-password') : t('label.show-password')
            }
          >
            {showPassword ? <EyeOpen /> : <EyeNone />}
          </ButtonIcon>
        </div>
      ) : null}

      {props.type === 'search' ? (
        <div
          className={clsx(
            styles.iconContainer,
            props.disabled && styles.iconDisabled
          )}
        >
          <Search className={styles.searchIcon} />
        </div>
      ) : null}
    </div>
  )
}

export default React.forwardRef<HTMLInputElement, InputProps>(Input)
