import Navigation from '@/components/common/Navigation/Navigation'

import styles from './NavigationLayout.module.scss'

const NavigationLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.wrapper}>
    <Navigation />
    <div className={styles.content}>{children}</div>
  </div>
)

export default NavigationLayout
