const specialCharsRegex = /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/

export const hasLargeLetter = (value: string) => /[A-Z]/.test(value)
export const hasSmallLetter = (value: string) => /[a-z]/.test(value)
export const hasNumber = (value: string) => /\d/g.test(value)
export const hasSpecialSign = (value: string) => specialCharsRegex.test(value)
export const has8Characters = (value: string) => value.length > 7

export const isPasswordCorrect = (value: string) =>
  hasLargeLetter(value) &&
  hasSmallLetter(value) &&
  hasNumber(value) &&
  hasSpecialSign(value) &&
  has8Characters(value)

export const fulfilledRequirementsCount = (value: string) =>
  [
    hasLargeLetter(value),
    hasSmallLetter(value),
    hasNumber(value),
    hasSpecialSign(value),
    has8Characters(value)
  ]
    .map(Boolean)
    .filter(Boolean).length
