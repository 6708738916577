import { z } from 'zod'

import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

export const activateLinkFormSchema = z.object({
  ids: z.array(z.string())
})

export type ActivateLinkFormPaylod = z.infer<typeof activateLinkFormSchema>

const responseSchema = z.object({
  successfully_sent: z.number(),
  unsuccessfully_sent: z.number()
})

export type ActivateLinkResponse = {
  successfullySent: number
  unsuccessfullySent: number
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): ActivateLinkResponse => {
  const parsed = responseSchema.parse(response)

  return {
    successfullySent: parsed.successfully_sent,
    unsuccessfullySent: parsed.unsuccessfully_sent
  }
}

const useSendActivationUserLink = ({
  onSuccess
}: {
  onSuccess: (data?: ActivateLinkResponse) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: ActivateLinkFormPaylod) => {
      const response =
        await httpService.panel.panelUsersSendActivationLinkCreate(data)

      return parseResponse(response)
    },
    onSuccess
  })

export default useSendActivationUserLink
