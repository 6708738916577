import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

type ClearAttendanceData = {
  id: string
}

const useClearAttendance = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: ClearAttendanceData) =>
      httpService.panel.panelLessonsClearAttendancePartialUpdate(data.id),
    onSuccess: options?.onSuccess
  })

export default useClearAttendance
