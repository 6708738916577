import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { LessonView } from '@/modules/lessonDetails'

export const Route = createFileRoute(
  '/_auth/timetable/$courseId/$groupId/lesson-details'
)({
  validateSearch: z.object({
    lessonId: z.string().catch('')
  }),
  component: LessonView
})
