export const DATE_FORMAT = 'dd.MM.yyyy'

export const DATE_PLACEHOLDER = 'DD.MM.YYYY'

export const TIME_FORMAT = 'HH:mm'

export const TIME_PLACEHOLDER = 'HH:MM'

export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'

export const API_DATE_FORMAT = 'yyyy-MM-dd'
export const API_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm'
