import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.object({
  preview_exists: z.boolean()
})

type PreviewExists = {
  previewExists: boolean
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): PreviewExists => ({ previewExists: response.preview_exists })

const usePreviewExists = () =>
  useQuery({
    queryKey: ['panelTimetablePreviewCheckRetrieve'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelTimetablePreviewCheckRetrieve()

      const parsed = parseResponse(responseSchema.parse(response))
      return parsed.previewExists
    }
  })

export default usePreviewExists
