import { useTranslation } from 'react-i18next'

import Button from '../Button/Button'
import ConfirmModal from '../ConfirmModal'

type DirtyModalProps = {
  isOpen: boolean
  onOpenChange: (value: boolean) => void
  onConfirm: () => void
}
const DirtyModal = (props: DirtyModalProps) => {
  const { t } = useTranslation(['common'])

  return (
    <ConfirmModal
      id="confirm-modal"
      header={t('text.cancel-changes')}
      subheader={t('text.changes-will-be-lost')}
      confirmButton={
        <Button variant="danger" onClick={props.onConfirm}>
          {t('button.confirm')}
        </Button>
      }
      open={props.isOpen}
      onOpenChange={props.onOpenChange}
    />
  )
}

export default DirtyModal
