import { useEffect } from 'react'
import type { UseFormReturn } from 'react-hook-form'

import type { FormError } from '@/types/form-error'

const useExternalErrors = <T extends Record<string, unknown>>(
  errors: FormError<T>[] | undefined,
  form: UseFormReturn<T>
) => {
  useEffect(
    () =>
      errors?.forEach(error =>
        form.setError(
          error.field,
          { message: error.message },
          { shouldFocus: true }
        )
      ),
    [form, errors]
  )
}

export default useExternalErrors
