import { getRouteApi } from '@tanstack/react-router'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import AssignmentIcon from '@/assets/icons/assignment.svg?react'
import Button from '@/components/Button/Button'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradesTable from '@/components/common/GradesTable'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import useGroup from '@/queries/useGroup'
import useStudentsGrades from '@/queries/useStudentsGrades'
import useAuthStore from '@/store/useAuthStore'

import styles from './GradesView.module.scss'
import useMarksList from '../../../queries/useMarksList'
import ColumnHeaderGradesView from '../components/ColumnHeaderGradesView'
import GradeColumnModal from '../components/GradeColumnModal'
import GradeSelectCell from '../components/GradeSelectCell'
import { hasEditGroupPermission } from '../utils/permissions'

const routeApi = getRouteApi('/_auth/timetable/$courseId/$groupId/grades')

const GradesView = () => {
  const { t } = useTranslation(['lessonDetails'])

  const navigate = routeApi.useNavigate()
  const { semesterId } = routeApi.useSearch()
  const { groupId, courseId } = routeApi.useParams()

  const { isMobile } = useScreenResolution()

  const user = useAuthStore(store => store.user)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    data: studentsGrades,
    isLoading,
    refetch
  } = useStudentsGrades({
    groupId,
    semesterId
  })

  const { data: group, isLoading: isGroupLoading } = useGroup(groupId)
  const { data: marks, isFetching: isMarksFetching } = useMarksList()

  const canChangeGrades = useMemo(
    () => hasEditGroupPermission(user, group),
    [user, group]
  )

  const handleChangeSemester = (semester?: string) => {
    navigate({
      search: {
        semesterId: semester || ''
      }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isGroupLoading,
        options: group?.semestersOptions || [],
        value: semesterId,
        placeholder: t('label.semester'),
        onChange: value => handleChangeSemester(value)
      }
    }
  ]

  return (
    <>
      <Filters
        filters={filters}
        hideClearAllButton
        actionsRender={
          canChangeGrades ? (
            <GradeColumnModal
              open={isModalOpen}
              groupId={groupId}
              semesterId={semesterId}
              onOpenChange={setIsModalOpen}
              onGradeColumnChange={refetch}
              trigger={
                <Button icon={<PlusIcon />} block={isMobile}>
                  {t('button.add-grade-column')}
                </Button>
              }
            />
          ) : null
        }
      />

      <GradesTable
        withBorders
        noHoverEffect
        studentsGrades={studentsGrades}
        isLoading={isLoading || isMarksFetching}
        gradeCell={({ grade, columnType, cellId }) =>
          canChangeGrades ? (
            <GradeSelectCell
              color={columnType !== 'custom' ? 'dark' : 'light'}
              dataTestId={cellId}
              value={grade.grade}
              gradesOptions={
                (columnType === 'behaviour'
                  ? marks?.behaviourMarksOptions
                  : marks?.marksOptions) || []
              }
              gradeItemId={grade.id}
              onGradeChange={refetch}
            />
          ) : grade.grade ? (
            marks?.marksMap.get(grade.grade)
          ) : null
        }
        customGradeColumnHeader={gradeColumn => (
          <ColumnHeaderGradesView
            canChangeGrades={canChangeGrades}
            onGradeColumnChange={refetch}
            tooltip={gradeColumn.name}
            column={gradeColumn}
            semesterId={semesterId}
            courseId={courseId}
            groupId={groupId}
            groupSemesters={group?.semesters || []}
          >
            <div className={styles.gradesColumnName}>
              {gradeColumn.assignmentId ? (
                <AssignmentIcon className={styles.assignmentIcon} />
              ) : null}
              {gradeColumn.name}
            </div>
          </ColumnHeaderGradesView>
        )}
      />
    </>
  )
}

export default GradesView
