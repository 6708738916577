import * as Sentry from '@sentry/react'
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import NavigationLayout from '@/components/common/NavigationLayout/NavigationLayout'
import { notifyError } from '@/utils/notify-error'
import { requiredString } from '@/utils/zod'

const userSchema = z.object({
  id: requiredString(),
  first_name: z.string(),
  last_name: z.string(),
  is_teacher: z.boolean(),
  is_superuser: z.boolean(),
  profiles: z.array(z.string()),
  tutor: z
    .object({
      classes: z.array(
        z.object({
          id: requiredString(),
          name: requiredString()
        })
      )
    })
    .nullable()
})

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location }) => {
    {
      if (!context.authStore || !context.authStore.isAuthenticated)
        throw redirect({
          to: '/login',
          search: {
            redirect: location.href
          }
        })
      if (!context.authStore.user) {
        try {
          const user = await httpService.panel.panelUsersMeRetrieve()
          const parsed = userSchema.parse(user)
          Sentry.setUser({
            id: parsed.id,
            firstName: parsed.first_name,
            lastName: parsed.last_name,
            isSuperAdmin: parsed.is_superuser,
            profiles: parsed.profiles,
            tutorClasses: parsed.tutor ? parsed.tutor.classes : undefined
          })
          context.authStore.setUser({
            id: parsed.id,
            firstName: parsed.first_name,
            lastName: parsed.last_name,
            isTeacher: parsed.is_teacher,
            isSuperAdmin: parsed.is_superuser,
            profiles: parsed.profiles,
            tutorClasses: parsed.tutor ? parsed.tutor.classes : undefined
          })
        } catch (error) {
          context.authStore.logOut()
          notifyError(error)
          throw redirect({
            to: '/login',
            search: {
              redirect: location.href
            }
          })
        }
      }
    }
  },
  component: () => (
    <NavigationLayout>
      <Outlet />
    </NavigationLayout>
  )
})
