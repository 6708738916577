import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FilterIcon from '@/assets/icons/filter.svg?react'
import BadgeFilter from '@/components/BadgeFilter/BadgeFilter'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal/Modal'

import styles from './FiltersModal.module.scss'

type FiltersModalProps = {
  children?: React.ReactNode
  selectedFilterCount?: number
}

const FiltersModal = (props: FiltersModalProps) => {
  const { t } = useTranslation(['common'])
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={styles.filtersModal}>
      <Modal
        id="modalFilters"
        title={t('header.filters')}
        contentClassName={styles.modalContentWrapper}
        open={isOpen}
        onOpenChange={setIsOpen}
        trigger={
          <Button variant="secondary" block className={styles.filterButton}>
            <span className={styles.textButton}>
              {t('button.filter')}

              <FilterIcon />

              {props.selectedFilterCount ? (
                <BadgeFilter text={props.selectedFilterCount} />
              ) : null}
            </span>
          </Button>
        }
        footer={
          <Button onClick={() => setIsOpen(false)}>{t('button.filter')}</Button>
        }
      >
        {props.children}
      </Modal>
    </div>
  )
}
export default FiltersModal
