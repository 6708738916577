import styles from './Loading.module.scss'
import Spinner from './Spinner'

type LoadingProps = {
  spinning?: boolean
  children: React.ReactNode
}

const Loading = (props: LoadingProps) => (
  <div className={styles.loading}>
    {props.spinning ? <Spinner /> : null}
    <div className={props.spinning ? styles.loadingBox : ''}>
      {props.children}
    </div>
  </div>
)

export default Loading
