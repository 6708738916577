import { sortBy, takeRight } from 'lodash'

import LinkText from '@/components/LinkText/LinkText'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import TruncateElementsList from '@/components/common/TruncateElementsList'
import useAuthStore from '@/store/useAuthStore'

import styles from './ClassesColumn.module.scss'
import { type GroupStudentClass } from '../types'

type ClassesColumnProps = {
  studentClasses: GroupStudentClass[]
}

const ClassesColumn = (props: ClassesColumnProps) => {
  const { user: userStore } = useAuthStore()

  const tutorClassesIds =
    userStore?.tutorClasses?.map(tutorClass => tutorClass.id) || []

  const myClasses = sortBy(
    props.studentClasses.filter(studentClass =>
      tutorClassesIds.includes(studentClass.id)
    ),
    'name'
  )

  const otherClasses = sortBy(
    props.studentClasses.filter(
      studentClass => !tutorClassesIds.includes(studentClass.id)
    ),
    'name'
  )
  const classesList = [...myClasses, ...otherClasses]
  const classesNamesList = classesList.map(studentClass => studentClass.name)

  return (
    <TruncateElementsList
      elipsis={hiddenItemsCount => (
        <Tooltip
          text={takeRight(classesNamesList, hiddenItemsCount).join(', ')}
          trigger={
            <Tag
              color="gray"
              variant="user-type"
              label={`+${hiddenItemsCount}`}
            />
          }
        />
      )}
    >
      {classesList.map((studentClass, index) => (
        <LinkText
          key={index}
          className={styles.classElement}
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: studentClass.id
          }}
        >
          {studentClass.name}
        </LinkText>
      ))}
    </TruncateElementsList>
  )
}

export default ClassesColumn
