import React from 'react'
import { useTranslation } from 'react-i18next'

import { type Route } from '@/components/Tabs/Tabs'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'

const StudentsAndClassesView = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { t } = useTranslation(['common'])

  const routes: Route[] = [
    {
      title: t('navigation.students'),
      link: {
        to: '/students-and-classes/students'
      }
    },
    {
      title: t('navigation.classes'),
      link: {
        to: '/students-and-classes/classes'
      }
    }
  ]

  return (
    <BasicLayout
      routes={routes}
      header={t('navigation.students-and-classes')}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      {children}
    </BasicLayout>
  )
}

export default StudentsAndClassesView
