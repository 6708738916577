import clsx from 'clsx'

import styles from './Textarea.module.scss'
import type { FormFieldType } from '../../types/form-field-type'

type TextareaProps = {
  placeholder?: string
  defaultValue?: string
  dataTestId?: string
  numberOfLines?: number
  disabled?: boolean
  maxLength?: number
} & FormFieldType<string>

const Textarea = (props: TextareaProps) => {
  const { numberOfLines = 2 } = props

  const isCharacterCountExceeded = !!(
    props.value &&
    props.maxLength &&
    props.value.length > props.maxLength
  )

  return (
    <div className={styles.inputWrapper}>
      <textarea
        data-test-id={props.dataTestId}
        className={styles.textarea}
        disabled={props.disabled}
        id={props.id}
        rows={numberOfLines}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value}
        onBlur={event => props.onBlur?.(event.target.value)}
        onChange={event => props.onChange?.(event.target.value)}
        aria-describedby={props.describedby}
        aria-invalid={props.invalid || isCharacterCountExceeded}
        aria-required={props.required}
        aria-disabled={props.disabled}
      />

      {props.maxLength ? (
        <div
          className={clsx(
            styles.counter,
            isCharacterCountExceeded && styles.counterExceeded
          )}
        >
          {`${props.value?.length || 0} / ${props.maxLength}`}
        </div>
      ) : null}
    </div>
  )
}

export default Textarea
