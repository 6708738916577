import { z } from 'zod'

import type {
  PanelLoginTokenResponse,
  PanelLoginValidateCodeRequest
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper,
  type ClientErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'
import type { CustomError } from '@/types/app-errors'

type TwoFactorPayload = { email: string; code: string }

const parseTwoFactor = (
  payload: TwoFactorPayload
): PanelLoginValidateCodeRequest => ({
  email: payload.email,
  code: Number(payload.code)
})

type CustomErrors = CustomError &
  (
    | {
        code: 'invalid_code'
        remainingAttempts: number
      }
    | {
        code: 'user_locked'
        lockUntil: Date
      }
  )

const userLockedExtraDataSchema = z.object({
  locked_until: z.string().datetime({ offset: true })
})

const userAttempsExtraDataSchema = z.object({
  failed_attempts: z.number(),
  max_attempts: z.number()
})

const clientErrorsMapper = (): ClientErrorsMapper<
  TwoFactorPayload,
  CustomErrors
> => ({
  invalid_code: extraData => {
    const parsed = userAttempsExtraDataSchema.parse(extraData)

    return {
      name: 'CustomError',
      code: 'invalid_code',
      message: 'Invalid code',
      remainingAttempts: parsed.max_attempts - parsed.failed_attempts
    }
  },
  user_locked: extraData => {
    const parsed = userLockedExtraDataSchema.parse(extraData)

    return {
      name: 'CustomError',
      code: 'user_locked',
      message: 'User is locked',
      lockUntil: new Date(parsed.locked_until)
    }
  }
})

const fieldErrorsMapper: FieldErrorsMapper<TwoFactorPayload> = {
  code: 'code',
  email: 'email',
  non_field_error: 'root'
}

const responseSchema = z.object({
  token: z.string().min(1),
  user: z.object({ phone_number: z.string().min(1), email: z.string().min(1) })
})

type Response = { token: string; user: { phoneNumber: string; email: string } }

const parseResponse = (response?: PanelLoginTokenResponse): Response => {
  const parsed = responseSchema.parse(response)
  return {
    token: parsed.token,
    user: {
      phoneNumber: parsed.user.phone_number,
      email: parsed.user.email
    }
  }
}

const useTwoFactor = (options?: {
  onSuccess?: (response?: Response) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: TwoFactorPayload) => {
      const response = await httpService.panel.panelAuthLoginValidateCodeCreate(
        parseTwoFactor(data)
      )
      return parseResponse(response)
    },
    onSuccess: data => options?.onSuccess?.(data),
    clientErrorsMapper: clientErrorsMapper(),
    fieldErrorsMapper
  })

export default useTwoFactor
