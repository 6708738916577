import Select, { type SelectOption } from '@/components/Select/Select'
import Tag, { type TagColor } from '@/components/Tag/Tag'

import styles from './TeacherSelect.module.scss'
import { type TeacherAvailability } from '../types'

type TeacherSelectProps = {
  id: string
  value?: string
  options: SelectOption<string>[]
  isOptionsLoading: boolean
  onChange?: (value?: string) => void
  teachersList: TeacherAvailability[]
  placeholder: string
}

const TeacherSelect = (props: TeacherSelectProps) => (
  <div className={styles.teacherSelect}>
    <Select
      id={props.id}
      placeholder={props.placeholder}
      mountedInBody
      value={props.value}
      options={props.options}
      loading={props.isOptionsLoading}
      formatOption={option => (
        <TeacherOption option={option} teachersList={props.teachersList} />
      )}
      onChange={value => props.onChange?.(value)}
    />
  </div>
)

type TeacherOptionProps = {
  option: SelectOption<string>
  teachersList: TeacherAvailability[]
}

const TeacherOption = (props: TeacherOptionProps) => {
  const teacherOption = props.teachersList?.find(
    teacher => teacher.id === props.option.value
  )

  return (
    <div className={styles.teacherOption}>
      <span>{props.option.label}</span>

      <Tag
        label={teacherOption?.status || ''}
        color={getColorByStatus(teacherOption?.status)}
      />
    </div>
  )
}

const getColorByStatus = (value?: string): TagColor => {
  switch (value) {
    case 'available':
      return 'green'
    case 'busy':
    case 'unavailable':
      return 'red'
    default:
      return 'gray'
  }
}

export default TeacherSelect
