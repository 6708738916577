import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserStatus } from '@/types/user-status'
import { userStatus } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    full_name: z.string().min(1),
    year: z.string().optional(),
    student_class: z
      .object({
        id: z.string().min(1),
        name: z.string().min(1)
      })
      .nullable()
      .optional(),
    tutor: z
      .object({
        id: z.string().min(1),
        full_name: z.string().min(1),
        first_name: z.string().min(1),
        last_name: z.string().min(1)
      })
      .nullable()
      .optional(),
    parents: z
      .array(
        z.object({
          id: z.string().min(1),
          full_name: z.string().min(1),
          first_name: z.string().min(1),
          last_name: z.string().min(1)
        })
      )
      .optional(),
    status: userStatus.optional()
  })
)

export type Student = {
  id: string
  fullName: string
  status?: UserStatus
  year?: string
  studentClass?: {
    id: string
    name: string
  }
  tutor?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
  }
  parents?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
  }[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Student[] =>
  response.map(item => ({
    id: item.id,
    fullName: item.full_name,
    status: item.status,
    year: item.year,
    studentClass: item.student_class
      ? {
          id: item.student_class.id,
          name: item.student_class.name
        }
      : undefined,
    tutor: item.tutor
      ? {
          id: item.tutor.id,
          fullName: item.tutor.full_name,
          firstName: item.tutor.first_name,
          lastName: item.tutor.last_name
        }
      : undefined,
    parents: item.parents
      ? item.parents.map(parent => ({
          id: parent.id,
          fullName: parent.full_name,
          firstName: parent.first_name,
          lastName: parent.last_name
        }))
      : []
  }))

type Params = {
  search?: string
  year?: string[]
  classId?: string[]
  tutor?: string[]
  parent?: string[]
  status?: UserStatus[]
  groupId?: string
  pageSize?: number | 'max'
  page?: number
  fetchKeys?: Record<string, boolean>
}

const useStudents = (params: Params = {}) =>
  useQuery({
    queryKey: ['panelStudentsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsList({
        search: params.search,
        year: params.year,
        class_id: params.classId,
        tutor_id: params.tutor,
        parent_id: params.parent,
        status: params.status,
        page_size: params.pageSize ? params.pageSize : 'max',
        group_id: params.groupId,
        page: params.page,
        fetchKeys: params.fetchKeys
          ? params.fetchKeys
          : {
              id: true,
              full_name: true
            }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))
      return {
        list: parsed,
        count: response.count,
        options: parsed.map(student => ({
          label: student.fullName,
          value: student.id
        }))
      }
    },
    placeholderData: previous => previous
  })

export default useStudents
