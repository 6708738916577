import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { AddGradesColumnPayload } from '../constants/grades-column-paylod'

const fieldErrorsMapper: FieldErrorsMapper<
  Omit<AddGradesColumnPayload, 'semesterId' | 'groupId'>
> = {
  name: 'name',
  semester_id: 'root',
  group_id: 'root',
  non_field_error: 'root'
}

const useAddGradesColumn = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: AddGradesColumnPayload) =>
      httpService.panel.panelGradesCreate({
        name: data.name,
        group: data.groupId,
        semester: data.semesterId
      }),
    fieldErrorsMapper,
    onSuccess
  })

export default useAddGradesColumn
