import { z } from 'zod'

export const topicAndNotesFormSchema = () =>
  z.object({
    topic: z.string(),
    notes: z.string()
  })

export type TopicAndNotesFormPayload = z.infer<
  ReturnType<typeof topicAndNotesFormSchema>
>
