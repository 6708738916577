import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Button from './Button/Button'
import styles from './ConfirmModal.module.scss'
import Divider from './Divider/Divider'

type ConfirmModalProps = {
  id: string
  header: string
  subheader: React.ReactNode | string
  open: boolean
  confirmButton?: React.ReactNode
  description?: React.ReactNode
  onOpenChange: (isOpen: boolean) => void
  onConfirm?: () => void
  onCancel?: () => void
}

const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation(['common'])
  return (
    <Dialog.Root open={props.open} onOpenChange={props.onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          id={props.id}
          aria-modal="true"
          aria-labelledby={`${props.id}-header`}
          className={clsx(styles.content)}
          data-test-id={`${props.id}-modal`}
        >
          <Dialog.Title className={styles.title} id={`${props.id}-label`}>
            {props.header}
          </Dialog.Title>

          {props.description ? (
            <p className={styles.description}>{props.description}</p>
          ) : null}

          <Divider className={styles.divider} />

          <div className={styles.subheader} data-test-id="modal-text">
            {props.subheader}
          </div>

          <div className={styles.footer}>
            <Dialog.Close asChild>
              <Button
                className={styles.closeButton}
                variant="tertiary"
                onClick={props.onCancel}
              >
                {t('button.cancel')}
              </Button>
            </Dialog.Close>

            {props.confirmButton ? (
              props.confirmButton
            ) : (
              <Button className={styles.submitButton} onClick={props.onConfirm}>
                {t('button.confirm')}
              </Button>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default ConfirmModal
