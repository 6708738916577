import { getRouteApi } from '@tanstack/react-router'
import {
  isAfter,
  isBefore,
  endOfISOWeek,
  format,
  startOfISOWeek
} from 'date-fns'
import { identity, isEmpty, omit, pickBy } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import InfoCircleFilled from '@/assets/icons/info-circle-filled.svg?react'
import CalendarBox from '@/components/CalendarBox/CalendarBox'
import Label from '@/components/Label'
import Switch from '@/components/Switch/Switch'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { API_DATE_FORMAT } from '@/constants/date-format'
import useClasses from '@/queries/useClasses'
import useCourses from '@/queries/useCourses'
import useSemesters from '@/queries/useSemesters'
import useStudents from '@/queries/useStudents'
import useTeachers from '@/queries/useTeachers'
import useAuthStore from '@/store/useAuthStore'

import styles from './TimetableView.module.scss'
import LessonEvent, {
  type LessonEventClickType,
  type LessonEventProps
} from '../components/LessonEvent'
import type { TimetableEvent } from '../constants/timetable-events'
import type { TimetableFiltersKey } from '../constants/timetable-filters'
import usePreviewExists from '../queries/usePreviewExists'
import useRooms from '../queries/useRooms'
import useTimetableEvents from '../queries/useTimetableEvents'
import useTimetablePreviewEvents from '../queries/useTimetablePreviewEvents'

const routeApi = getRouteApi('/_auth/timetable/')

const TimetableView = () => {
  const { t } = useTranslation(['timetable'])

  const searchParams = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const user = useAuthStore(store => store.user)

  const [previewEnabled, setPreviewEnabled] = useState(false)

  const isSomeFilterExist = !isEmpty(
    pickBy(omit(searchParams, ['dateFrom', 'dateTo']), identity)
  )

  const { data: events, isFetching: isTimetableEventsLoading } =
    useTimetableEvents(searchParams, isSomeFilterExist && !previewEnabled)

  const { data: eventsPreview, isFetching: isTimetablePreviewEventsLoading } =
    useTimetablePreviewEvents(searchParams, isSomeFilterExist && previewEnabled)

  const { data: semesters } = useSemesters()
  const { data: previewExists } = usePreviewExists()

  const { data: teachers, isLoading: isTeachersLoading } = useTeachers()
  const { data: students, isLoading: isStudentsLoading } = useStudents()
  const { data: courses, isLoading: isCoursesLoading } = useCourses()
  const { data: classes, isLoading: isClassesLoading } = useClasses()
  const { data: rooms, isLoading: isRoomsLoading } = useRooms()

  const sortedTeacherOptions = [...(teachers?.options || [])].sort((a, b) => {
    if (a.value === user?.id) return -1
    if (b.value === user?.id) return 1
    return 0
  })

  const isUserTeacher =
    !!sortedTeacherOptions.length &&
    !!user &&
    sortedTeacherOptions[0].value === user?.id

  const changeFilter = (key: TimetableFiltersKey, value?: string) => {
    navigate({
      search: () => ({
        [key]: value,
        dateFrom: searchParams.dateFrom,
        dateTo: searchParams.dateTo
      })
    })
  }

  const canSeeLessonDetails = (event: TimetableEvent) =>
    !!user?.isSuperAdmin ||
    (user?.id
      ? [
          event.teacher.id,
          event.teacherCover?.id,
          event.coTeacherCover?.id,
          event.coTeacher?.id,
          ...event.tutors
        ].includes(user.id)
      : false)
  const currentSemester = semesters?.list.find(
    semester =>
      !isBefore(searchParams.dateFrom, semester.startDate) &&
      !isAfter(searchParams.dateFrom, semester.endDate)
  )

  const handleEventClick = ({
    lessonId,
    courseId,
    groupId,
    isPreview
  }: LessonEventClickType) => {
    if (!isPreview && groupId) {
      navigate({
        to: '/timetable/$courseId/$groupId/lesson-details',
        params: {
          courseId,
          groupId
        },
        search: {
          lessonId
        }
      })
    }
  }

  const parseEventProps = (event: TimetableEvent): LessonEventProps => ({
    id: event.id,
    startDate: event.startDate,
    endDate: event.endDate,
    course: event.course,
    groupId: event.group?.id,
    teacherCoverName: event.teacherCover?.name,
    coTeacherCoverName: event.coTeacherCover?.name,
    teacherName: event.teacher.name,
    coTeacherName: event.coTeacher?.name,
    classNamesText: event.classNames.join(', '),
    roomName: event.room?.name,
    isCancelled: event.isCancelled,
    onClick: handleEventClick
  })

  const timetableEvents = previewEnabled
    ? eventsPreview?.map(event => ({
        start: event.startDate,
        end: event.endDate,
        dataTestId: 'lesson-preview-slot',
        component: <LessonEvent {...parseEventProps(event)} isPreview />
      })) || []
    : events?.map(event => ({
        start: event.startDate,
        end: event.endDate,
        dataTestId: 'lesson-slot',
        component: (
          <LessonEvent
            {...parseEventProps(event)}
            clickable={canSeeLessonDetails(event)}
          />
        )
      })) || []

  const handleOnDateChange = (date: Date) => {
    navigate({
      to: '/timetable',
      search: {
        ...searchParams,
        dateFrom: format(startOfISOWeek(date), API_DATE_FORMAT),
        dateTo: format(endOfISOWeek(date), API_DATE_FORMAT)
      }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.teacher'),
      variant: 'select',
      filterProps: {
        id: 'teacher',
        firstOptionFixed: isUserTeacher,
        loading: isTeachersLoading,
        options: sortedTeacherOptions,
        value: searchParams.teacher,
        placeholder: t('help.select-teacher'),
        onChange: value => changeFilter('teacher', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'select',
      filterProps: {
        id: 'student',
        loading: isStudentsLoading,
        options: students?.options || [],
        value: searchParams.student,
        placeholder: t('help.select-student'),
        onChange: value => changeFilter('student', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'select',
      filterProps: {
        id: 'course',
        loading: isCoursesLoading,
        options: courses?.options || [],
        value: searchParams.course,
        placeholder: t('help.select-course'),
        onChange: value => changeFilter('course', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'select',
      filterProps: {
        id: 'group',
        loading: isClassesLoading,
        options: classes?.options || [],
        value: searchParams.classId,
        placeholder: t('help.select-class'),
        onChange: value => changeFilter('classId', value)
      }
    },
    {
      label: t('label.room'),
      variant: 'select',
      filterProps: {
        id: 'room',
        loading: isRoomsLoading,
        options: rooms?.options || [],
        value: searchParams.room,
        placeholder: t('help.select-room'),
        onChange: value => changeFilter('room', value)
      }
    }
  ]

  return (
    <BasicLayout
      moduleName={t('header.academics', { ns: 'common' })}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>
            {currentSemester?.name ? (
              <SplittedHeader header={currentSemester.name} />
            ) : (
              t('header.timetable')
            )}
          </h1>
          <div className={styles.previewWrapper}>
            <div className={styles.box}>
              <Label id="preview" label={t('label.preview-enable')} hidden />

              <Switch
                label={
                  previewEnabled
                    ? t('label.preview-on')
                    : t('label.preview-off')
                }
                labelClassName={styles.previewSwitchLabel}
                id="preview"
                variant="header"
                value={previewEnabled}
                disabled={!previewExists}
                onChange={value => {
                  setPreviewEnabled(value)
                }}
              />

              <Tooltip
                text={t('help.timetable-preview')}
                trigger={<InfoCircleFilled className={styles.infoIcon} />}
              />
            </div>

            {previewExists ? (
              <Tag label={t('text.new-preview-available')} variant="on-blue" />
            ) : null}
          </div>
        </div>
      }
    >
      <Filters filters={filters} hideClearAllButton />

      <CalendarBox
        events={timetableEvents}
        loading={isTimetableEventsLoading || isTimetablePreviewEventsLoading}
        initialDate={searchParams.dateFrom}
        onDateChange={handleOnDateChange}
        height="auto"
      />
    </BasicLayout>
  )
}

const SplittedHeader = ({ header }: { header: string }) => {
  const lastSpaceIndex = header.lastIndexOf(' ')

  const part1 = header.substring(0, lastSpaceIndex)
  const part2 = header.substring(lastSpaceIndex + 1)

  return (
    <>
      {part1} <span className={styles.headerThin}>{part2}</span>
    </>
  )
}
export default TimetableView
