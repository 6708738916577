import { Api } from './generated-api.gen'

export const httpService = new Api({
  baseURL: import.meta.env.VITE_API_URL
})

httpService.instance.interceptors.request.use(config => {
  if (window.localStorage.getItem('token')) {
    config.headers['Authorization'] =
      'Token ' + window.localStorage.getItem('token')
  }

  config.headers['school-id'] = import.meta.env.VITE_SCHOOL_ID

  config.paramsSerializer = {
    indexes: null
  }
  return config
})
