import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { type LinkProps, Link } from '@tanstack/react-router'

import type { routeTree } from '@/routeTree.gen'

import styles from './Tabs.module.scss'

export type Route = {
  title: string
  link: Pick<LinkProps<typeof routeTree>, 'to' | 'params' | 'search'>
}

type TabsProps = {
  routes: Route[]
}

const Tabs = (props: TabsProps) => (
  <NavigationMenu.Root orientation="vertical">
    <NavigationMenu.List className={styles.tabs}>
      {props.routes.map(item => (
        <NavigationMenu.Item key={item.link.to}>
          <NavigationMenu.Link className={styles.navigationLink} asChild>
            <Link
              to={item.link.to}
              params={item.link.params}
              search={item.link.search}
              className={styles.link}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={() => ({ className: styles.linkActive })}
            >
              <span>{item.title}</span>
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Item>
      ))}
    </NavigationMenu.List>
  </NavigationMenu.Root>
)
export default Tabs
