import { createFileRoute } from '@tanstack/react-router'

import { timetableFiltersSchema, TimetableView } from '@/modules/timetable'

export const Route = createFileRoute('/_auth/timetable/')({
  component: () => <TimetableView />,
  validateSearch: search => timetableFiltersSchema.parse(search),
  loaderDeps: ({ search }) => ({
    ...search
  }),

  loader: ({ context: { authStore }, deps: search, navigate }) => {
    if (authStore?.user?.isTeacher) {
      const isNoneFilterExist =
        !search.classId &&
        !search.course &&
        !search.room &&
        !search.student &&
        !search.teacher

      navigate({
        to: '/timetable',
        search: {
          ...search,
          teacher:
            isNoneFilterExist && authStore?.user?.isTeacher
              ? authStore?.user.id
              : search.teacher || undefined
        }
      })
    }
  },
  pendingComponent: () => <div>Loading...</div>
})
