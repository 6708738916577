import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { GradesView } from '@/modules/lessonDetails'

export const Route = createFileRoute(
  '/_auth/timetable/$courseId/$groupId/grades'
)({
  validateSearch: z.object({
    semesterId: z.string().catch('')
  }),
  component: GradesView
})
