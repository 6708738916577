import { useTranslation } from 'react-i18next'

import styles from './RemoveAssignmentSubheader.module.scss'

type RemoveAssignmentSubheaderProps = {
  isGradedAssignment: boolean
}

const RemoveAssignmentSubheader = (props: RemoveAssignmentSubheaderProps) => {
  const { t } = useTranslation(['lessonDetails'])

  return (
    <div className={styles.wrapper}>
      <>
        {props.isGradedAssignment ? (
          <p>{t('help.column-will-be-removed')}</p>
        ) : null}

        <p>{t('help.assignment-will-be-removed-from-app')}</p>
      </>
      <p>{t('help.all-will-be-lost')}</p>
    </div>
  )
}

export default RemoveAssignmentSubheader
