import type { TagColor } from '@/components/Tag/Tag'
import type { UserStatus } from '@/types/user-status'

export const getStatusTagColor = (value: UserStatus): TagColor => {
  switch (value) {
    case 'active':
      return 'green'
    case 'inactive':
      return 'blue'
    case 'blocked':
      return 'red'
  }
}
