import clsx from 'clsx'
import { OTPInput } from 'input-otp'
import { useTranslation } from 'react-i18next'

import Label from '@/components/Label'
import type { FormFieldType } from '@/types/form-field-type'

import styles from './PinInput.module.scss'

type PinInputProps = {
  length: number
  onComplete?: (value: string) => void
  disabled?: boolean
} & FormFieldType<string>

const PinInput = (props: PinInputProps) => {
  const { t } = useTranslation(['common'])
  return (
    <>
      <Label id={props.id} label={t('label.pin-input')} hidden />

      <OTPInput
        disabled={props.disabled}
        autoFocus
        maxLength={props.length}
        onComplete={value => props.onComplete?.(value)}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        render={({ slots }) => (
          <div
            className={clsx(
              styles.wrapper,
              props.invalid && styles.wrapperError
            )}
          >
            {slots.map((slot, index) => (
              <div
                key={index}
                className={clsx(
                  styles.slot,
                  slot.isActive && styles.slotActive,
                  props.disabled && styles.slotDisabled
                )}
              >
                {slot.char !== null ? (
                  <div>
                    {props.value?.length === props.length
                      ? '\u25CF'
                      : slot.char}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      />
    </>
  )
}

export default PinInput
