import clsx from 'clsx'

import Loader from '@/assets/icons/loader.svg?react'

import styles from './InlineSpinner.module.scss'

type InlineSpinnerProps = { className?: string }

const InlineSpinner = (props: InlineSpinnerProps) => (
  <Loader className={clsx(props.className, styles.spin)} />
)

export default InlineSpinner
