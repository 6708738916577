import * as React from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'

import CloseIcon from '@/assets/icons/close.svg?react'

import styles from './Drawer.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

type DrawerProps = {
  trigger: React.ReactNode
  children: React.ReactNode
  open?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

const Drawer = (props: DrawerProps) => (
  <DrawerPrimitive.Root
    open={props.open}
    onOpenChange={value => {
      props.onOpenChange?.(value)
    }}
  >
    <DrawerPrimitive.Trigger asChild>{props.trigger}</DrawerPrimitive.Trigger>
    <DrawerPrimitive.Portal>
      <DrawerPrimitive.Overlay className={styles.overlay} />
      <DrawerPrimitive.Content className={styles.content}>
        {props.children}

        <DrawerPrimitive.Close asChild>
          <ButtonIcon
            className={styles.closeIcon}
            size="small"
            aria-label="Close"
            variant="tertiary"
          >
            <CloseIcon />
          </ButtonIcon>
        </DrawerPrimitive.Close>
      </DrawerPrimitive.Content>
      <DrawerPrimitive.Overlay />
    </DrawerPrimitive.Portal>
  </DrawerPrimitive.Root>
)

export default Drawer
