import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserStatus } from '@/types/user-status'
import { requiredString, userStatus } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    email: requiredString(),
    phone_number: requiredString(),
    profiles: z.array(z.string()),
    is_superuser: z.boolean(),
    status: userStatus.optional()
  })
)

export type User = {
  id: string
  name: string
  email: string
  phoneNumber: string
  profiles: string[]
  isSuperUser: boolean
  status?: UserStatus
}

const parseResponse = (response: z.infer<typeof responseSchema>): User[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    email: item.email,
    phoneNumber: item.phone_number,
    profiles: item.is_superuser ? [...item.profiles, 'Admin'] : item.profiles,
    isSuperUser: item.is_superuser,
    status: item.status
  }))

type Params = {
  id?: string[]
  profile?: string[]
  email?: string[]
  phoneNumber?: string[]
  status?: UserStatus[]
  pageSize?: number | 'max'
  page?: number
}

const userProfileList = ['Teacher', 'Tutor', 'User', 'Superuser'] as const

type UserProfile = (typeof userProfileList)[number]

export function isUserProfile(profile?: string): profile is UserProfile {
  return userProfileList.includes(profile as UserProfile)
}

const useUsers = (params: Params = {}) =>
  useQuery({
    queryKey: ['userList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const userList = await httpService.panel.panelUsersList({
        page: params.page,
        page_size: params.pageSize,
        id: params.id,
        email: params.email,
        phone_number: params.phoneNumber,
        profile: params.profile?.filter(profile => !!isUserProfile(profile)),
        status: params.status,
        fetchKeys: {
          id: true,
          name: true,
          profiles: true,
          email: true,
          phone_number: true,
          status: true,
          is_superuser: true
        }
      })

      const parsedUserList = parseResponse(
        responseSchema.parse(userList.results)
      )
      return {
        list: parsedUserList,
        count: userList.count
      }
    }
  })

export default useUsers
