import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.object({
  tutor: z.object({
    id: requiredString(),
    full_name: requiredString(),
    first_name: requiredString(),
    last_name: requiredString(),
    phone_number: requiredString(),
    email: requiredString()
  }),
  year: requiredString(),
  name: requiredString(),
  number_of_students: z.number(),
  capacity: z.number().optional(),
  preferred_room: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  students: z.array(
    z.object({
      id: requiredString(),
      full_name: requiredString(),
      first_name: requiredString(),
      last_name: requiredString(),
      parents: z
        .array(
          z.object({
            id: requiredString(),
            full_name: requiredString(),
            first_name: requiredString(),
            last_name: requiredString(),
            phone_number: requiredString()
          })
        )
        .optional()
    })
  )
})

export type Class = {
  tutor: {
    id: string
    fullName: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
  }
  year: string
  name: string
  numberOfStudents: number
  capacity?: number
  preferredRoom?: {
    id: string
    name: string
  }
  students: {
    id: string
    fullName: string
    firstName: string
    lastName: string
    parents?: {
      id: string
      fullName: string
      firstName: string
      lastName: string
      phoneNumber: string
    }[]
  }[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Class => ({
  tutor: {
    id: response.tutor.id,
    fullName: response.tutor.full_name,
    firstName: response.tutor.first_name,
    lastName: response.tutor.last_name,
    phoneNumber: response.tutor.phone_number,
    email: response.tutor.email
  },
  year: response.year,
  name: response.name,
  numberOfStudents: response.number_of_students,
  capacity: response.capacity,
  preferredRoom: response.preferred_room
    ? {
        id: response.preferred_room.id,
        name: response.preferred_room.name
      }
    : undefined,
  students: response.students
    ? response.students.map(student => ({
        id: student.id,
        fullName: student.full_name,
        firstName: student.first_name,
        lastName: student.last_name,
        parents: student.parents
          ? student.parents.map(parent => ({
              id: parent.id,
              fullName: parent.full_name,
              firstName: parent.first_name,
              lastName: parent.last_name,
              phoneNumber: parent.phone_number
            }))
          : []
      }))
    : []
})

export const classQueryOptions = (id: string) => ({
  queryKey: ['class', id],
  staleTime: 60 * 100,
  queryFn: async () => {
    const classesResponse = await httpService.panel.panelClassesRetrieve(id)
    return parseResponse(responseSchema.parse(classesResponse))
  }
})

const useClass = (id: string) => useQuery(classQueryOptions(id))

export default useClass
