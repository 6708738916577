import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import type { StringOption } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = z.object({
  courses: arrayOfOptions,
  students: arrayOfOptions,
  teachers: arrayOfOptions,
  co_teachers: arrayOfOptions,
  groups: arrayOfOptions
})

export type Filters = {
  coursesOptions: StringOption[]
  studentsOptions: StringOption[]
  teachersOptions: StringOption[]
  coTeachersOptions: StringOption[]
  groupsOptions: StringOption[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Filters => ({
  coursesOptions: response.courses.map(course => ({
    value: course.value,
    label: course.label
  })),
  studentsOptions: response.students.map(student => ({
    value: student.value,
    label: student.label
  })),
  teachersOptions: response.teachers.map(teacher => ({
    value: teacher.value,
    label: teacher.label
  })),
  coTeachersOptions: response.co_teachers.map(teacher => ({
    value: teacher.value,
    label: teacher.label
  })),
  groupsOptions: response.groups.map(group => ({
    value: group.value,
    label: group.label
  }))
})

type Params = {
  classId: string
  semester: string
}

const useClassGroupsFilters = (params: Params) =>
  useQuery({
    queryKey: ['panelClassesGroupsOptionsRetrieve', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelClassesGroupsOptionsRetrieve(
          params.classId,
          params.semester
        )

      const parsed = parseResponse(responseSchema.parse(response))
      return parsed
    }
  })

export default useClassGroupsFilters
