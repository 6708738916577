import { createFileRoute } from '@tanstack/react-router'

import {
  StudentCoursesAndGradesView,
  studentCoursesFiltersSchema
} from '@/modules/students'

export const Route = createFileRoute(
  '/_auth/students-and-classes/students/$studentId/courses-and-grades'
)({
  validateSearch: search => studentCoursesFiltersSchema.parse(search),
  component: StudentCoursesAndGradesView
})
