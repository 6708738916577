import { getRouteApi } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import AssignmentIcon from '@/assets/icons/assignment.svg?react'
import TextWithLabel from '@/components/TextWithLabel/TextWithLabel'
import Tooltip from '@/components/Tooltip/Tooltip'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradesTable from '@/components/common/GradesTable'
import useMarksList from '@/queries/useMarksList'
import useSemesters from '@/queries/useSemesters'
import useStudentsGrades from '@/queries/useStudentsGrades'

import styles from './ClassGradesView.module.scss'
import useClassGradesFilters from '../queries/useClassGradesFilters'
import useGroupTeachers from '../queries/useGroupTeachers'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/$classId/grades'
)

const ClassGradesView = () => {
  const { t } = useTranslation(['classes'])

  const { groupId, semesterId, courseId } = routeApi.useSearch()
  const { classId } = routeApi.useParams()
  const navigate = routeApi.useNavigate()

  const { data: marks } = useMarksList()
  const { data: group } = useGroupTeachers(groupId)
  const { data: studentsGrades, isLoading } = useStudentsGrades({
    groupId,
    semesterId,
    classId
  })

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const { data: filtersOptions, isLoading: isGroupsFilterPending } =
    useClassGradesFilters({
      semesterId,
      classId
    })

  const onCourseChange = (value: string | undefined) => {
    const firstCourseFromSelect = filtersOptions?.coursesOptions[0].value
    const newCourseId = value || firstCourseFromSelect
    if (!newCourseId) return
    const newGroupId = filtersOptions?.groupsByCourseMap[newCourseId][0].value

    navigate({
      search: {
        semesterId,
        courseId: newCourseId,
        groupId: newGroupId
      }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: semesterId,
        placeholder: t('label.semester'),
        onChange: value => {
          navigate({
            search: {
              semester: value
            }
          })
        }
      }
    },
    {
      label: t('label.course'),
      variant: 'select',
      filterProps: {
        id: 'course',
        dataTestId: 'course-filter',
        loading: isGroupsFilterPending,
        options: filtersOptions?.coursesOptions || [],
        value: courseId,
        placeholder: t('label.course'),
        onChange: value => onCourseChange(value)
      }
    },
    {
      label: t('label.group'),
      variant: 'select',
      filterProps: {
        id: 'group',
        dataTestId: 'group-filter',
        loading: isGroupsFilterPending,
        options: courseId
          ? filtersOptions?.groupsByCourseMap[courseId] || []
          : [],
        value: groupId,
        placeholder: t('label.group'),
        onChange: value => {
          navigate({
            search: previousValue => ({
              ...previousValue,
              groupId: value
            })
          })
        }
      }
    }
  ]

  useEffect(() => {
    if (!groupId && filtersOptions) {
      const newCourseId = filtersOptions.coursesOptions[0]?.value
      const newGroupId =
        filtersOptions.groupsByCourseMap[newCourseId]?.[0]?.value

      navigate({
        search: {
          semesterId,
          courseId: newCourseId,
          groupId: newGroupId
        },
        replace: true
      })
    }
  }, [filtersOptions, groupId, isGroupsFilterPending, navigate, semesterId])

  return (
    <>
      <Filters filters={filters} hideClearAllButton />

      {!!groupId ? (
        <>
          <div className={styles.gradesHeader}>
            <TextWithLabel
              label={t('label.teacher')}
              dataTestId="teacher"
              text={group?.teacher.fullName}
            />
            <TextWithLabel
              label={t('label.co-teacher')}
              dataTestId="co-teacher"
              text={group?.coTeacher?.fullName}
            />
          </div>
          <GradesTable
            isLoading={isLoading}
            studentsGrades={studentsGrades}
            gradeCell={({ grade, cellId }) => (
              <div className={styles.gradeCell} data-test-id={cellId}>
                {grade.grade ? marks?.marksMap.get(grade.grade) : null}
              </div>
            )}
            customGradeColumnHeader={column => (
              <Tooltip
                renderTriggerAsChild
                trigger={
                  <div className={styles.headerCell}>
                    {column.assignmentId ? (
                      <AssignmentIcon className={styles.assignmentIcon} />
                    ) : null}
                    <span className={styles.headerText}>{column.name}</span>
                  </div>
                }
                text={column.name}
              />
            )}
          />
        </>
      ) : null}
    </>
  )
}

export default ClassGradesView
