import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import type { StringOption } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = z.object({
  groups: arrayOfOptions,
  courses: arrayOfOptions,
  teachers: arrayOfOptions,
  co_teachers: arrayOfOptions,
  students: arrayOfOptions,
  classes: arrayOfOptions
})

export type CoursesFilters = {
  groupsOptions: StringOption[]
  coursesOptions: StringOption[]
  teachersOptions: StringOption[]
  coTeachersOptions: StringOption[]
  studentsOptions: StringOption[]
  classesOptions: StringOption[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): CoursesFilters => ({
  groupsOptions: response.groups,
  coursesOptions: response.courses,
  teachersOptions: response.teachers,
  coTeachersOptions: response.co_teachers,
  studentsOptions: response.students,
  classesOptions: response.classes
})

type Params = {
  teacherOrCoTeacherId?: string
  tutorId?: string
  semester?: string
}

const useCoursesFilters = (params: Params) =>
  useQuery({
    queryKey: ['coursesFilters', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelLessonsGroupsOptionsRetrieve({
          tutor_id: params.tutorId,
          teacher_or_co_teacher_id: params.teacherOrCoTeacherId,
          semester_id: params.semester,
          fetchKeys: {
            groups: true,
            courses: true,
            teachers: true,
            co_teachers: true,
            students: true,
            classes: true
          }
        })

      return parseResponse(responseSchema.parse(response))
    },
    enabled: !!params.semester
  })

export default useCoursesFilters
