import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string().min(1)
  })
)

type Room = {
  id: string
  name: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Room[] =>
  response.map(item => ({
    id: item.id,
    name: item.name
  }))

const useRooms = () =>
  useQuery({
    queryKey: ['panelRoomsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelRoomsList({
        page_size: 'max',
        fetchKeys: {
          id: true,
          name: true
        }
      })

      const parsed = parseResponse(responseSchema.parse(response.results))

      return {
        options: parsed.map(group => ({
          label: group.name,
          value: group.id
        }))
      }
    }
  })

export default useRooms
