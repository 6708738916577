import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { CoverTeacherFormSchema } from '../constants/cover-payload'

const fieldErrorsMapper: FieldErrorsMapper<CoverTeacherFormSchema> = {
  teacher_cover: 'teacher',
  co_teacher_1_cover: 'coTeacher',
  non_field_error: 'root'
}

const useSetCover = (
  lessonId: string,
  options?: {
    onSuccess: (validData: CoverTeacherFormSchema) => void
    onError: () => void
  }
) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: CoverTeacherFormSchema) =>
      httpService.panel.panelLessonsCoverPartialUpdate(lessonId, {
        teacher_cover: data.teacher || null,
        co_teacher_1_cover: data.coTeacher || null
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    onError: () => options?.onError?.(),
    fieldErrorsMapper
  })

export default useSetCover
