import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { requiredString } from '@/utils/zod'

export const coursesAndGroupsFiltersSchema = z.object({
  semester: requiredString().catch(''),
  courseId: z.array(z.string()).optional(),
  groupId: z.array(z.string()).optional(),
  studentId: z.array(z.string()).optional(),
  teacherId: z.array(z.string()).optional(),
  coTeacherId: z.array(z.string()).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type CoursesAndGroupsFiltersKey = keyof z.infer<
  typeof coursesAndGroupsFiltersSchema
>
