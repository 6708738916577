import { type SelectOption } from '@/components/Select/Select'
import i18n from '@/i18n'

export const addUnassignedFilterOption = (
  options: SelectOption<string>[]
): SelectOption<string>[] => {
  const newOptions = [...options]

  newOptions.unshift({
    label: i18n.t('text.unassigned', { ns: 'common' }),
    value: 'unassigned'
  })

  return newOptions
}
