import './styles/global.scss'
import { QueryClientProvider } from '@tanstack/react-query'
import {
  ErrorComponent,
  RouterProvider,
  createRouter
} from '@tanstack/react-router'
import { lazy, useEffect } from 'react'

import Spinner from './components/Spinner'
import Toaster from './components/Toast/Toaster'
import queryClient from './queryClient'
import { routeTree } from './routeTree.gen'
import useAuthStore from './store/useAuthStore'

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => (
    <div>
      <Spinner />
    </div>
  ),
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  context: { authStore: null, queryClient },
  defaultPreloadStaleTime: 0
})

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools
        }))
      )

const ReactQueryDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/react-query-devtools').then(res => ({
          default: res.ReactQueryDevtools
        }))
      )

declare module '@tanstack/react-router' {
  // eslint-disable-next-line no-restricted-syntax
  interface Register {
    router: typeof router
  }
}

function App() {
  const authStore = useAuthStore()

  useEffect(() => {
    router.invalidate()
  }, [authStore])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} context={{ authStore, queryClient }} />
      <Toaster />
      <TanStackRouterDevtools position="bottom-right" router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
