import { z } from 'zod'

import i18n from '@/i18n'

export const formSchemaCoverTeacher = () =>
  z
    .object({
      teacher: z.string().optional(),
      coTeacher: z.string().optional()
    })
    .refine(data => !data.teacher && !data.coTeacher, {
      path: ['root'],
      message: i18n.t('error.one-field-is-required', { ns: 'lessonDetails' })
    })

export type CoverTeacherFormSchema = z.infer<
  ReturnType<typeof formSchemaCoverTeacher>
>
