import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { Grade, StudentsGrades } from '@/types/grades'

const responseSchema = z.object({
  columns: z.array(
    z.object({
      name: z.string(),
      id: z.string().min(1),
      assignment_id: z.string().nullable(),
      kind: z.union([
        z.literal('regular'),
        z.literal('proposed'),
        z.literal('behaviour'),
        z.literal('final')
      ])
    })
  ),
  students_grades: z.array(
    z.object({
      student: z.object({
        id: z.string().min(1),
        full_name: z.string(),
        first_name: z.string(),
        last_name: z.string()
      }),
      grades: z.array(
        z.object({
          id: z.string().min(1),
          column_id: z.string().min(1),
          mark_id: z.string().nullable()
        })
      ),
      attendance_percentage: z.number()
    })
  )
})

const parseResponse = (
  response: z.infer<typeof responseSchema>
): StudentsGrades => {
  const regularColumns =
    response.columns.filter(column => column.kind === 'regular') || []

  const findColumnIdByKind = (kind: 'behaviour' | 'final' | 'proposed') =>
    response.columns.find(column => column.kind === kind)?.id

  const behaviourColumnId = findColumnIdByKind('behaviour')
  const finalColumnId = findColumnIdByKind('final')
  const proposedColumnId = findColumnIdByKind('proposed')

  if (!behaviourColumnId || !finalColumnId || !proposedColumnId)
    throw new Error(`No behaviour, final or proposed column`)

  const isFromFixedColumn = (columnId: string) =>
    [behaviourColumnId, finalColumnId, proposedColumnId].includes(columnId)

  return {
    columns: regularColumns?.map(column => ({
      id: column.id,
      name: column.name,
      assignmentId: column.assignment_id
    })),
    students: response.students_grades.map(studentGrades => {
      const getGradeById = (id: string) =>
        studentGrades.grades.find(grade => grade.column_id === id)

      const behaviour = getGradeById(behaviourColumnId)
      const final = getGradeById(finalColumnId)
      const proposed = getGradeById(proposedColumnId)

      if (!behaviour || !final || !proposed)
        throw new Error(
          `No behaviour, final or proposed grade in student object with id: ${studentGrades.student.id}`
        )

      return {
        student: {
          id: studentGrades.student.id,
          fullName: studentGrades.student.full_name,
          lastName: studentGrades.student.last_name,
          firstName: studentGrades.student.first_name
        },
        gradeColumns: studentGrades.grades.reduce<Record<string, Grade>>(
          (prev, grade) =>
            isFromFixedColumn(grade.column_id)
              ? prev
              : {
                  ...prev,
                  [`${grade.column_id}`]: { id: grade.id, grade: grade.mark_id }
                },
          {}
        ),
        attendance: studentGrades.attendance_percentage,
        behaviour: { id: behaviour.id, grade: behaviour.mark_id },
        proposedGrade: { id: proposed.id, grade: proposed.mark_id },
        finalGrade: { id: final.id, grade: final.mark_id },
        id: studentGrades.student.id
      }
    })
  }
}

const useStudentsGrades = ({
  groupId,
  semesterId,
  classId
}: {
  groupId?: string
  semesterId: string
  classId?: string
}) =>
  useQuery({
    queryKey: ['panelGradesSummaryClass', groupId, semesterId, classId],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!groupId) return
      const response = await httpService.panel.panelGradesSummaryRetrieve(
        groupId,
        semesterId,
        {
          class_id: classId,
          ordering: 'last_name,first_name'
        }
      )
      return parseResponse(responseSchema.parse(response))
    },
    enabled: !!groupId && !!semesterId
  })

export default useStudentsGrades
