import InfoCircle from '@/assets/icons/info-circle.svg?react'

import styles from './Section.module.scss'
import Tooltip from './Tooltip/Tooltip'

type SectionProps = {
  title: string
  tooltip?: string
  children?: React.ReactNode
  dataTestId?: string
}

const Section = (props: SectionProps) => (
  <section data-test-id={props.dataTestId}>
    <h2 className={styles.title}>
      {props.title}
      {!!props.tooltip ? (
        <Tooltip
          text={props.tooltip}
          trigger={<InfoCircle className={styles.icon} />}
        />
      ) : null}
    </h2>
    {props.children}
  </section>
)

export default Section
