import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import LinkText from '@/components/LinkText/LinkText'
import Table, { type Column } from '@/components/Table/Table'
import CourseRowDetails from '@/components/common/CourseRowDetails'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradebookButton from '@/components/common/GradebookButton'
import useCourseGroup from '@/queries/useCourseGroup'
import useSemesters from '@/queries/useSemesters'

import type { CoursesAndGroupsFiltersKey } from '../constants/courses-and-groups-filters'
import useClassGroups, { type Group } from '../queries/useClassGroups'
import useClassGroupsFilters from '../queries/useClassGroupsFilters'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/$classId/courses-and-groups'
)

const ClassCoursesAndGroups = () => {
  const { t } = useTranslation(['classes'])
  const { classId } = routeApi.useParams()
  const {
    page,
    pageSize,
    courseId,
    groupId,
    studentId,
    teacherId,
    semester,
    coTeacherId
  } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()
  const { data: filtersOptions, isLoading: isFiltersPending } =
    useClassGroupsFilters({
      classId,
      semester
    })

  const { data: groups, isLoading } = useClassGroups({
    classId,
    page,
    pageSize,
    courseId,
    groupId,
    studentId,
    teacherId,
    semester,
    coTeacherId
  })

  const columns: Column<Group>[] = [
    {
      title: t('header.course'),
      key: 'course',
      dataIndex: ['course', 'name'],
      cellDataTestId: 'course',
      width: 215
    },
    {
      title: t('header.group'),
      key: 'group',
      cellDataTestId: 'group',
      dataIndex: 'name',
      width: 215
    },
    {
      title: t('header.teacher'),
      key: 'teacher',
      cellDataTestId: 'teacher',
      render: (value: Group) =>
        value.teacher ? (
          <LinkText
            to="/users/$userId/details"
            params={{
              userId: value.teacher?.id
            }}
          >
            {value.teacher.fullName}
          </LinkText>
        ) : null,
      width: 215
    },
    {
      title: t('header.co-teacher'),
      key: 'co-teacher',
      cellDataTestId: 'co-teacher',
      render: (value: Group) =>
        value.coTeacher ? (
          <LinkText
            to="/users/$userId/details"
            params={{
              userId: value.coTeacher.id
            }}
          >
            {value.coTeacher.fullName}
          </LinkText>
        ) : null,
      width: 215
    },
    {
      key: 'gradebook',
      render: (value: Group) => (
        <GradebookButton
          courseId={value.course.id}
          groupId={value.id}
          semesterId={semester}
        />
      ),
      width: 165
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester
      }
    })
  }

  const changeFilter = (
    key: CoursesAndGroupsFiltersKey,
    value?: string | string[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        dataTestId: 'semester-filter',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: semester,
        placeholder: t('label.semester'),
        onChange: value => {
          navigate({
            search: {
              semester: value
            }
          })
        }
      }
    },
    {
      label: t('label.student'),
      variant: 'multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.studentsOptions || [],
        value: studentId,
        placeholder: t('label.student'),
        onChange: value => changeFilter('studentId', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        dataTestId: 'course-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.coursesOptions || [],
        value: courseId,
        placeholder: t('label.course'),
        onChange: value => changeFilter('courseId', value)
      }
    },
    {
      label: t('label.group'),
      variant: 'multiselect',
      filterProps: {
        id: 'group',
        dataTestId: 'group-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.groupsOptions || [],
        value: groupId,
        placeholder: t('label.group'),
        onChange: value => changeFilter('groupId', value)
      }
    },
    {
      label: t('label.teacher'),
      variant: 'multiselect',
      filterProps: {
        id: 'teacher',
        dataTestId: 'teacher-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.teachersOptions || [],
        value: teacherId,
        placeholder: t('label.teacher'),
        onChange: value => changeFilter('teacherId', value)
      }
    },
    {
      label: t('label.co-teacher'),
      variant: 'multiselect',
      filterProps: {
        id: 'co-teacher',
        dataTestId: 'co-teacher-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.coTeachersOptions || [],
        value: coTeacherId,
        placeholder: t('label.co-teacher'),
        onChange: value => changeFilter('coTeacherId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter => filter.filterProps.value && filter.filterProps.id !== 'semester'
  )

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="class-groups"
        data={groups?.list || []}
        columns={columns}
        isLoading={isLoading}
        pagination={{
          count: groups?.count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => (
          <CourseExpandendRow groupId={record.id} semesterId={semester} />
        )}
      />
    </>
  )
}

const CourseExpandendRow = (props: {
  groupId: string
  semesterId?: string
}) => {
  const { data: group, isLoading } = useCourseGroup(
    props.groupId,
    props.semesterId
  )

  return (
    <CourseRowDetails isLoading={isLoading} students={group?.students || []} />
  )
}
export default ClassCoursesAndGroups
