import clsx from 'clsx'

import styles from './Divider.module.scss'

type DividerProps = {
  text?: string
  variant?: 'light' | 'dark' | 'on-blue'
  className?: string
}

const Divider = (props: DividerProps) => {
  const { variant = 'light' } = props
  return (
    <div className={clsx(styles.dividerWrapper, props.className)}>
      <span className={clsx(styles.dividerLine, styles[variant])} />
      {props.text ? <span className={styles.text}>{props.text}</span> : null}
      <span className={clsx(styles.dividerLine, styles[variant])} />
    </div>
  )
}

export default Divider
