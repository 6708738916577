import clsx from 'clsx'
import {
  addDays,
  isSameDay,
  isToday,
  isWeekend,
  startOfISOWeek
} from 'date-fns'

import { formatDay, formatShortWeekdayName } from '@/utils/format-date'

import styles from './CalendarMobileHeader.module.scss'

type CalendarMobileHeaderProps = {
  date: Date
  onDateChange: (date: Date) => void
}

const CalendarMobileHeader = (props: CalendarMobileHeaderProps) => {
  const weekStart = startOfISOWeek(props.date)
  const days = Array(7)
    .fill(0)
    .map((_, index) => addDays(weekStart, index))

  const handleClick = (day: Date) => {
    props.onDateChange(day)
  }

  return (
    <div className={styles.mobileHeader}>
      <div className={styles.days}>
        {days.map((day, index) => (
          <div
            role="button"
            tabIndex={0}
            key={index}
            className={clsx(
              styles.dayWrapper,
              isSameDay(props.date, day) && styles.daySelected,
              isToday(day) && styles.today
            )}
            onClick={() => handleClick(day)}
          >
            <span
              className={clsx(styles.day, isWeekend(day) && styles.weekend)}
            >
              {formatDay(day)}
            </span>
            <span className={styles.dayName}>
              {formatShortWeekdayName(day)}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CalendarMobileHeader
