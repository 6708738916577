import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Collapse from '@/components/Collapse/Collapse'
import DataBlock from '@/components/DataBlock/DataBlock'
import LinkText from '@/components/LinkText/LinkText'

import styles from './ClassDetailsView.module.scss'
import useClass from '../queries/useClass'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/$classId/details'
)

const ClassDetailsView = () => {
  const { t } = useTranslation(['classes'])
  const { classId } = routeApi.useParams()

  const { data: classData } = useClass(classId)

  const basicInformationInfo = [
    {
      label: t('label.year'),
      value: classData?.year,
      dataTestId: 'year'
    },
    {
      label: t('label.capacity'),
      value: `${classData?.numberOfStudents}/${classData?.capacity} `,
      dataTestId: 'class-capacity'
    },
    {
      label: t('label.preferred-room'),
      value: classData?.preferredRoom?.name,
      dataTestId: 'preffered-room'
    }
  ]
  const tutorInfo = [
    {
      label: t('label.tutor'),
      value: classData?.tutor.fullName,
      dataTestId: 'tutor'
    },
    {
      label: t('label.tutor-phone-number'),
      value: classData?.tutor.phoneNumber,
      dataTestId: 'tutor-phone-number'
    },
    {
      label: t('label.tutor-email'),
      value: classData?.tutor.email,
      dataTestId: 'tutor-email'
    }
  ]

  return (
    <>
      <div className={styles.sectionWrapper}>
        <h3 className={styles.sectionHeader}>{t('header.details')}</h3>
        <div className={styles.dataBlocksWrapper}>
          <DataBlock
            header={t('header.basic-information')}
            infoArray={basicInformationInfo}
          />
          <DataBlock header={t('header.tutor')} infoArray={tutorInfo} />
        </div>
      </div>

      <div className={styles.sectionWrapper} data-test-id="students-section">
        <h3 className={styles.sectionHeader}>{t('header.students')}</h3>

        <div>
          {classData?.students.map(student => {
            const parentsInfo =
              student.parents?.map(parent => ({
                label: parent.fullName,
                value: parent.phoneNumber
              })) || []

            return (
              <Collapse
                key={student.id}
                header={
                  <LinkText
                    className={styles.classElement}
                    to="/students-and-classes/students/$studentId/details"
                    params={{
                      studentId: student.id
                    }}
                  >
                    <h4 className={styles.studentName} data-test-id="student">
                      {student.fullName}
                    </h4>
                  </LinkText>
                }
              >
                {parentsInfo.length ? (
                  <DataBlock
                    header={t('header.guardians')}
                    infoArray={parentsInfo}
                    size="large"
                  />
                ) : (
                  <div className={styles.noDataBox}>
                    {t('text.no-guardians-assigned')}
                  </div>
                )}
              </Collapse>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ClassDetailsView
