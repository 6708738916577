import { z } from 'zod'

import { requiredString } from '@/utils/zod'

export const responseSchema = z.array(
  z.object({
    class_names: z.array(z.string()),
    co_teacher_1: z
      .object({ name: requiredString(), id: requiredString() })
      .nullable(),
    course: z.object({ name: requiredString(), id: requiredString() }),
    end_date: z.string().datetime({ offset: true }),
    event_type: z.literal('lesson'),
    group: z.object({ id: requiredString() }).nullable(),
    id: requiredString(),
    room: z.object({ name: requiredString(), id: requiredString() }).nullable(),
    start_date: z.string().datetime({ offset: true }),
    teacher: z.object({ name: requiredString(), id: requiredString() }),
    teacher_cover: z
      .object({ name: requiredString(), id: requiredString() })
      .nullable(),
    tutors: z.array(z.string()),
    is_cancelled: z.boolean(),
    co_teacher_1_cover: z
      .object({ name: requiredString(), id: requiredString() })
      .nullable()
  })
)

type EventType = 'lesson'

export type TimetableEvent = {
  id: string
  startDate: Date
  endDate: Date
  classNames: string[]
  eventType: EventType
  group: {
    id: string
  } | null
  course: {
    id: string
    name: string
  }
  room: {
    id: string
    name: string
  } | null
  teacher: {
    name: string
    id: string
  }
  teacherCover: {
    name: string
    id: string
  } | null
  coTeacherCover: {
    name: string
    id: string
  } | null
  coTeacher: {
    name: string
    id: string
  } | null
  tutors: string[]
  isCancelled: boolean
}

export const parseResponse = (
  response: z.infer<typeof responseSchema>
): TimetableEvent[] =>
  response.map(item => ({
    id: item.id,
    startDate: new Date(item.start_date),
    endDate: new Date(item.end_date),
    classNames: item.class_names,
    eventType: item.event_type,
    group: item.group,
    course: item.course,
    room: item.room,
    teacher: item.teacher,
    teacherCover: item.teacher_cover,
    coTeacherCover: item.co_teacher_1_cover,
    coTeacher: item.co_teacher_1,
    tutors: item.tutors,
    isCancelled: item.is_cancelled
  }))

export type Params = {
  dateFrom: string
  dateTo: string
  student?: string
  teacher?: string
  course?: string
  classId?: string
  room?: string
}
