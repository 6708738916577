import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { AssignmentsView } from '@/modules/lessonDetails'

export const Route = createFileRoute(
  '/_auth/timetable/$courseId/$groupId/assignments'
)({
  validateSearch: z.object({
    open: z.string().optional()
  }),
  component: AssignmentsView
})
