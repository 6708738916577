import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import Label from '@/components/Label'
import Select from '@/components/Select/Select'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import useAuthStore from '@/store/useAuthStore'

export const Route = createFileRoute('/_auth/profile')({
  component: ProfileComponent
})

function ProfileComponent() {
  const { i18n } = useTranslation()

  const changeLanguageHandler = (lang: string) => {
    window.localStorage.setItem('language', lang)
    i18n.changeLanguage(lang)

    document.documentElement.setAttribute('lang', lang)
  }

  const languageOptions = [
    { value: 'de', label: 'de' },
    { value: 'en-GB', label: 'en' }
  ]

  const authStore = useAuthStore()
  const navigate = useNavigate()
  return (
    <BasicLayout
      header={`${authStore?.user?.firstName} ${authStore?.user?.lastName}`}
    >
      <div style={{ maxWidth: '200px' }}>
        <Label id="select" label="Select language:" />
        <Select
          id="language"
          value={i18n.language}
          options={languageOptions}
          hideSearch
          onChange={value => {
            if (value) changeLanguageHandler(value)
          }}
        />
      </div>
      <div>
        <Button
          onClick={() => {
            authStore.logOut()
            navigate({ to: '/login', search: { redirect: Route.fullPath } })
          }}
        >
          Log out
        </Button>
      </div>
    </BasicLayout>
  )
}
