import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

type NewPasswordPayload = {
  password: string
  repeatPassword: string
  token: string
}

type ErrorsPayload = Omit<NewPasswordPayload, 'token'>

const fieldErrorsMapper: FieldErrorsMapper<ErrorsPayload> = {
  password: 'password'
}

const useNewPassword = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: NewPasswordPayload) =>
      httpService.panel.panelAuthNewPasswordValidateCodeCreate(data),
    onSuccess: options?.onSuccess,
    fieldErrorsMapper
  })

export default useNewPassword
