import { createFileRoute, MatchRoute, Outlet } from '@tanstack/react-router'

import i18n from '@/i18n'
import { UsersListView, usersFiltersSchema } from '@/modules/users'

export const Route = createFileRoute('/_auth/users')({
  validateSearch: usersFiltersSchema,
  component: () => (
    <>
      <MatchRoute to="/users">
        <UsersListView />
      </MatchRoute>
      <Outlet />
    </>
  ),
  meta: () => [
    { title: i18n.t('navigation.teachers-and-users', { ns: 'common' }) }
  ]
})
