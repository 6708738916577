import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserStatus } from '@/types/user-status'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1)
  })
)

export type Student = {
  id: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Student[] =>
  response.map(item => ({
    id: item.id
  }))

type Params = {
  search?: string
  year?: string[]
  classId?: string[]
  tutor?: string[]
  parent?: string[]
  status?: UserStatus[]
  groupId?: string
}

const useStudentsAll = (params: Params = {}) =>
  useQuery({
    queryKey: ['panelStudentsListAll', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsList({
        search: params.search,
        year: params.year,
        class_id: params.classId,
        tutor_id: params.tutor,
        parent_id: params.parent,
        status: params.status,
        page_size: 'max',
        group_id: params.groupId,
        page: 1,
        fetchKeys: {
          id: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))
      return {
        list: parsed.map(student => student.id),
        count: response.count
      }
    }
  })

export default useStudentsAll
