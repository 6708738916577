import { type LinkProps, Link } from '@tanstack/react-router'
import clsx from 'clsx'
import React from 'react'

import type { routeTree } from '@/routeTree.gen'

import styles from './Button.module.scss'
import InlineSpinner from '../InlineSpinner/InlineSpinner'

type ButtonSize = 'small' | 'medium'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'tertiary'
  | 'danger'
  | 'success'

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onPointerDown?: () => void
  children: React.ReactNode
  type?: 'submit' | 'button'
  icon?: React.ReactNode
  block?: boolean
  dataTestId?: string
  disabled?: boolean
  loading?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
  ariaChecked?: boolean
  ariaLabel?: string
  ariaControls?: string
  ariaExpanded?: boolean
  className?: string
  role?: 'button' | 'switch'
} & (
  | {
      asLink?: false
    }
  | ({ asLink: true } & LinkProps<typeof routeTree>)
)

const Button = (
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const {
    variant = 'primary',
    size = 'medium',
    role = 'button',
    type = 'button'
  } = props

  const className = clsx(
    styles.button,
    props.block && styles.block,
    styles[variant],
    styles[size],
    props.className
  )

  return (
    <>
      {props.asLink ? (
        <Link
          to={props.to}
          search={props.search}
          params={props.params}
          className={className}
          data-test-id={props.dataTestId}
        >
          {props.children}
        </Link>
      ) : (
        <button
          onPointerDown={props.onPointerDown}
          className={className}
          ref={ref}
          role={role}
          aria-checked={props.ariaChecked}
          aria-controls={props.ariaControls}
          aria-expanded={props.ariaExpanded}
          onClick={props.onClick}
          disabled={props.disabled || props.loading}
          data-test-id={props.dataTestId}
          type={type}
          aria-label={props.ariaLabel}
        >
          <span className={styles.buttonText}>{props.children}</span>

          {props.loading ? (
            <span className={styles.icon}>
              <InlineSpinner />
            </span>
          ) : props.icon ? (
            <span className={styles.icon}>{props.icon}</span>
          ) : null}
        </button>
      )}
    </>
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button)
