import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import type { Path } from 'react-hook-form'
import { z } from 'zod'

import i18n from '@/i18n'
import type {
  CustomError,
  UnknownError,
  ValidationError
} from '@/types/app-errors'

const validationErrorSchema = z.object({
  type: z.literal('validation_error'),
  errors: z.array(
    z.object({
      detail: z.string(),
      code: z.string().min(1),
      attr: z.string().min(1)
    })
  )
})

const clientErrorSchema = z.object({
  type: z.union([z.literal('client_error'), z.literal('server_error')]),
  errors: z
    .array(
      z.object({
        detail: z.string(),
        code: z.string().min(1),
        attr: z.null(),
        extra_data: z
          .object({})
          .catchall(z.union([z.string(), z.number(), z.null()]))
          .nullish()
      })
    )
    .length(1)
})

const backendErrorSchema = z.union([validationErrorSchema, clientErrorSchema])

type ExtraData = Record<string, string | number | null> | null

export type NormalizedErrors<T extends Record<string, unknown>> =
  | UnknownError
  | ValidationError<T>

export type FieldErrorsMapper<T> = Record<
  string,
  ((field: string) => Path<T> | 'root') | Path<T> | 'root'
>

export type ClientErrorsMapper<
  T extends Record<string, unknown>,
  TCustomError extends CustomError
> = Record<string, (extraData?: ExtraData) => ValidationError<T> | TCustomError>

const errorsMap = (): Record<string, string> => ({
  required: i18n.t('error.required-field', { ns: 'common' }),
  blank: i18n.t('error.required-field', { ns: 'common' })
})

export const normalizeValidationErrors = <T extends Record<string, unknown>>(
  error: z.TypeOf<typeof validationErrorSchema>,
  fieldErrorsMapper?: FieldErrorsMapper<T>
): ValidationError<T> => ({
  name: 'ValidationError',
  message: i18n.t('error.form-validation-error', { ns: 'common' }),
  errors: error.errors.map(({ attr, code, detail }) => {
    const fieldMap = fieldErrorsMapper?.[attr]
    return {
      field: fieldMap
        ? typeof fieldMap === 'string'
          ? fieldMap
          : fieldMap(attr)
        : 'root',
      message: errorsMap()[code] || detail
    }
  })
})

export const normalizeCustomErrors = <
  TPayload extends Record<string, unknown>,
  TCustomError extends CustomError
>(
  error: z.TypeOf<typeof clientErrorSchema>['errors'][number],
  mapper?: ClientErrorsMapper<TPayload, TCustomError>
): NormalizedErrors<TPayload> | TCustomError => {
  const errorParse = mapper?.[error.code]

  if (!errorParse)
    return {
      name: 'UnknownError',
      message: error.detail
    }
  return errorParse(error.extra_data)
}

export function formatErrors<
  T extends Record<string, unknown>,
  CustomErrors extends CustomError
>(normalizedErrors: CustomErrors | NormalizedErrors<T> | null) {
  return {
    error: normalizedErrors,
    customErrors:
      normalizedErrors?.name === 'CustomError' ? normalizedErrors : undefined,
    formErrors:
      normalizedErrors?.name === 'ValidationError'
        ? normalizedErrors.errors
        : undefined
  }
}

const useMutationWithErrorsHandling = <
  TApiResponse,
  TPayload extends Record<string, unknown>,
  TCustomError extends CustomError,
  TErrorsMap extends Record<string, unknown>
>(
  options: UseMutationOptions<
    TApiResponse | undefined,
    NormalizedErrors<TPayload> | TCustomError,
    TPayload
  > & {
    clientErrorsMapper?: ClientErrorsMapper<TPayload, TCustomError>
    fieldErrorsMapper?: TPayload extends TErrorsMap
      ? FieldErrorsMapper<TErrorsMap> | (() => FieldErrorsMapper<TErrorsMap>)
      : FieldErrorsMapper<TPayload> | (() => FieldErrorsMapper<TPayload>)
  }
) => {
  const {
    clientErrorsMapper,
    fieldErrorsMapper,
    mutationFn,
    ...mutationOptions
  } = options
  const { error: mutationError, ...rest } = useMutation<
    TApiResponse | undefined,
    NormalizedErrors<TPayload> | TCustomError,
    TPayload
  >({
    mutationFn: async (props: TPayload) => {
      try {
        return await mutationFn?.(props)
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.data) {
            const parsed = backendErrorSchema.parse(error.response.data)
            throw parsed.type === 'validation_error'
              ? normalizeValidationErrors(
                  parsed,
                  typeof fieldErrorsMapper === 'function'
                    ? fieldErrorsMapper()
                    : fieldErrorsMapper
                )
              : normalizeCustomErrors(parsed.errors[0], clientErrorsMapper)
          }
        }
        throw error
      }
    },
    ...mutationOptions
  })
  return {
    ...formatErrors(mutationError),
    ...rest
  }
}

export default useMutationWithErrorsHandling
