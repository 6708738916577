import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import type { StringOption } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = z.object({
  courses: arrayOfOptions,
  teachers: arrayOfOptions
})

export type Filters = {
  coursesOptions: StringOption[]
  teachersOptions: StringOption[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Filters => ({
  coursesOptions: response.courses.map(course => ({
    value: course.value,
    label: course.label
  })),
  teachersOptions: response.teachers.map(teacher => ({
    value: teacher.value,
    label: teacher.label
  }))
})

type Params = {
  semesterId: string
  studentId: string
}

const useCoursesSummaryFilters = (params: Params) =>
  useQuery({
    queryKey: ['panelCoursesSummaryOptionsRetrieve', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelCoursesSummaryOptionsRetrieve(
          params.semesterId,
          params.studentId
        )

      const parsed = parseResponse(responseSchema.parse(response))
      return parsed
    }
  })

export default useCoursesSummaryFilters
