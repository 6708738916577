import styles from './Spinner.module.scss'

const Spinner = () => (
  <div className={styles.loadingDots}>
    {Array.from({ length: 4 }, (_, index) => (
      <div className={styles.dot} key={index} />
    ))}
  </div>
)

export default Spinner
