import { MatchRoute, Outlet, createFileRoute } from '@tanstack/react-router'

import { ClassesView, classesFiltersSchema } from '@/modules/classes'

export const Route = createFileRoute('/_auth/students-and-classes/classes')({
  validateSearch: search => classesFiltersSchema.parse(search),
  component: () => (
    <>
      <MatchRoute to="/students-and-classes/classes">
        <ClassesView />
      </MatchRoute>
      <Outlet />
    </>
  )
})
