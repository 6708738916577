import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { ZodError } from 'zod'

import { toast } from './hooks/useToast'
import i18n from './i18n'
import useAuthStore from './store/useAuthStore'

const handleError = (error: Error) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    useAuthStore.getState().logOut()
  } else if (error instanceof ZodError) {
    throw error.format()
  } else {
    toast({ variant: 'error', title: i18n.t('error.common', { ns: 'common' }) })
    throw error
  }
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: handleError
  }),
  mutationCache: new MutationCache({
    onError: error => {
      if (error.name !== 'ValidationError' && error.name !== 'CustomError') {
        handleError(error)
      }
    }
  })
})

export default queryClient
