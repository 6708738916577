import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { LoginView } from '@/modules/auth'

export const Route = createFileRoute('/login')({
  beforeLoad: ({ context }) => {
    if (context.authStore?.isAuthenticated) {
      throw redirect({
        to: '/',
        replace: true
      })
    }
  },
  validateSearch: z.object({
    redirect: z.string().catch('/')
  }),
  component: LoginView
})
