import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { Semester } from '@/types/semester'
import { date, requiredString } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString(),
  semesters: z.array(
    z.object({
      id: requiredString(),
      name: requiredString(),
      start_date: date(),
      end_date: date()
    })
  ),
  teacher_covers: z.array(z.string()),
  co_teacher_1_covers: z.array(z.string()),
  teacher: z.object({
    id: requiredString(),
    name: requiredString()
  }),
  co_teacher_1: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable()
})

export type Group = {
  id: string
  name: string
  semesters: Semester[]
  teacherCovers: string[]
  coTeacherCovers: string[]
  teacher: { id: string; fullName: string }
  coTeacher?: { id: string; fullName: string }
}

const parseResponse = (response: z.infer<typeof responseSchema>): Group => ({
  id: response.id,
  name: response.name,
  semesters: response.semesters.map(semester => ({
    id: semester.id,
    name: semester.name,
    startDate: semester.start_date,
    endDate: semester.end_date
  })),
  teacherCovers: response.teacher_covers,
  coTeacherCovers: response.co_teacher_1_covers,
  teacher: { id: response.teacher.id, fullName: response.teacher.name },
  coTeacher: response.co_teacher_1
    ? {
        id: response.co_teacher_1.id,
        fullName: response.co_teacher_1.name
      }
    : undefined
})

const useGroup = (id: string) =>
  useQuery({
    queryKey: ['panelLessonsGroupsRetrieve', id],
    staleTime: 60 * 100,
    queryFn: async () => {
      const groupResponse = await httpService.panel.panelLessonsGroupsRetrieve(
        id,
        {
          fetchKeys: {
            id: true,
            name: true,
            semesters: true,
            teacher_covers: true,
            co_teacher_1_covers: true,
            teacher: true,
            co_teacher_1: true
          }
        }
      )

      const group = parseResponse(responseSchema.parse(groupResponse))

      return {
        ...group,
        semestersOptions: group.semesters.map(semester => ({
          label: semester.name,
          value: semester.id
        }))
      }
    }
  })

export default useGroup
