import { useTranslation } from 'react-i18next'

import CourseRowDetails from '@/components/common/CourseRowDetails'
import useCourseGroup from '@/queries/useCourseGroup'
import useAuthStore from '@/store/useAuthStore'

import styles from './CourseExpandedRow.module.scss'

type CourseExpandendRowProps = {
  groupId: string
  semesterId?: string
  withSplittedStudentsList?: boolean
}

const CourseExpandendRow = (props: CourseExpandendRowProps) => {
  const { t } = useTranslation('courses')

  const { data: group, isLoading } = useCourseGroup(
    props.groupId,
    props.semesterId
  )

  const { user: userStore } = useAuthStore()

  const tutorClassesIds =
    userStore?.tutorClasses?.map(tutorClass => tutorClass.id) || []

  const studentsFromMyClass =
    group?.students.filter(
      student =>
        student.studentClass &&
        tutorClassesIds.includes(student.studentClass.id)
    ) || []

  const studentsFromOtherClass =
    group?.students.filter(
      student =>
        (student.studentClass &&
          !tutorClassesIds.includes(student.studentClass.id)) ||
        !student.studentClass
    ) || []

  return props.withSplittedStudentsList ? (
    <div className={styles.courseDetailsContainer}>
      <div className={styles.courseDetailsBox}>
        <span className={styles.courseDetailsHeader}>
          {t('header.students-from-my-class')}:
        </span>
        <CourseRowDetails
          withClassColumn
          isLoading={isLoading}
          students={studentsFromMyClass}
        />
      </div>

      {studentsFromOtherClass.length ? (
        <div>
          <span className={styles.courseDetailsHeader}>
            {t('header.students-from-other-classes')}:
          </span>
          <CourseRowDetails
            withClassColumn
            isLoading={isLoading}
            students={studentsFromOtherClass}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <CourseRowDetails
      withClassColumn
      isLoading={isLoading}
      students={group?.students || []}
    />
  )
}
export default CourseExpandendRow
