import { createFileRoute } from '@tanstack/react-router'

import {
  ClassCoursesAndGroupsView,
  coursesAndGroupsFiltersSchema
} from '@/modules/classes'

export const Route = createFileRoute(
  '/_auth/students-and-classes/classes/$classId/courses-and-groups'
)({
  validateSearch: search => coursesAndGroupsFiltersSchema.parse(search),
  component: ClassCoursesAndGroupsView
})
