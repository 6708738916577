import { zodResolver } from '@hookform/resolvers/zod'
import React, { useImperativeHandle } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import { useCountdown } from '@/hooks/useCountDown'
import i18n from '@/i18n'

import PinInput from './PinInput/PinInput'
import styles from './TwoFactorForm.module.scss'

const CODE_LENGTH = 6

const formSchema = () =>
  z.object({
    code: z.string().length(CODE_LENGTH, {
      message: i18n.t('error.incorrect-code-length', {
        ns: 'auth',
        LENGTH: CODE_LENGTH
      })
    })
  })

type TwoFactorFormPayload = z.infer<ReturnType<typeof formSchema>>

type TwoFactorFormProps = {
  onSubmit: (payload: TwoFactorFormPayload) => void
  remainingAttempts?: number | null
  loading?: boolean
  formBlockedUntil?: Date | null
  children?: React.ReactNode
}

export type TwoFactorFormComponentRef = {
  form: UseFormReturn<TwoFactorFormPayload, unknown, undefined>
}

export const TwoFactorForm = (
  props: TwoFactorFormProps,
  ref: React.ForwardedRef<TwoFactorFormComponentRef>
) => {
  const { t } = useTranslation(['auth'])

  const form = useForm<TwoFactorFormPayload>({
    resolver: zodResolver(formSchema()),
    mode: 'onSubmit',
    defaultValues: {
      code: ''
    }
  })

  const userLockedCountdown = useCountdown(props.formBlockedUntil?.getTime())

  const handleOnSubmit = (payload: TwoFactorFormPayload) => {
    if (!userLockedCountdown.isCounting) props.onSubmit(payload)
  }

  useImperativeHandle(
    ref,
    () => ({
      form
    }),
    [form]
  )

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)}>
        <FormField
          control={form.control}
          id="code"
          name="code"
          render={({ inputProps }) => (
            <PinInput
              {...inputProps}
              onComplete={code => handleOnSubmit({ code })}
              length={CODE_LENGTH}
            />
          )}
        />

        <Button
          type="submit"
          block
          className={styles.confirmButton}
          loading={props.loading}
          disabled={userLockedCountdown.isCounting}
        >
          {t('button.confirm')}
        </Button>

        {props.children}

        {props.remainingAttempts ? (
          <Alert
            message={
              t('error.incorrect-pin') +
              (props.remainingAttempts <= 3
                ? ' ' +
                  t('error.remaining-attempts', {
                    ATTEMPTS: props.remainingAttempts
                  })
                : '')
            }
            variant="error"
            className={styles.alert}
          />
        ) : null}
        {userLockedCountdown.isCounting ? (
          <Alert
            message={t('help.login-will-be-unlocked', {
              TIME: userLockedCountdown.timeText
            })}
            className={styles.alert}
            variant="info"
          />
        ) : null}
      </form>
    </FormProvider>
  )
}

export default React.forwardRef<TwoFactorFormComponentRef, TwoFactorFormProps>(
  TwoFactorForm
)
