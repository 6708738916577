import type { ClientErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import type { CustomError } from '@/types/app-errors'

type CustomErrors = CustomError & {
  code: 'invalid_status'
}
type Payload = { id: string }

export const clientErrorsMapper = (): ClientErrorsMapper<
  Payload,
  CustomErrors
> => ({
  invalid_status: () => ({
    name: 'CustomError',
    code: 'invalid_status',
    message: 'Invalid status'
  })
})
