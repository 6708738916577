import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.object({
  id: z.string().min(1),
  teacher: z.object({ id: z.string(), name: z.string() }),
  co_teacher_1: z.object({ id: z.string(), name: z.string() }).nullable()
})

export type Group = {
  id: string
  teacher: { id: string; fullName: string }
  coTeacher?: { id: string; fullName: string }
}

const parseResponse = (response: z.infer<typeof responseSchema>): Group => ({
  id: response.id,
  teacher: {
    id: response.teacher.id,
    fullName: response.teacher.name
  },
  coTeacher: response.co_teacher_1
    ? {
        id: response.co_teacher_1.id,
        fullName: response.co_teacher_1.name
      }
    : undefined
})

const useGroupTeachers = (id?: string) =>
  useQuery({
    queryKey: ['groupTeachers', id],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!id) return
      const response = await httpService.panel.panelLessonsGroupsRetrieve(id, {
        fetchKeys: {
          id: true,
          teacher: true,
          co_teacher_1: true
        }
      })

      const parsed = parseResponse(responseSchema.parse(response))

      return {
        ...parsed
      }
    },
    enabled: !!id
  })

export default useGroupTeachers
