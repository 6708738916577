import React from 'react'
import { useTranslation } from 'react-i18next'

import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { getRoutesWithPermission } from '@/modules/courses'
import useAuthStore from '@/store/useAuthStore'

const CoursesView = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['courses'])
  const { user } = useAuthStore()

  const routes = getRoutesWithPermission(user || undefined)

  return (
    <BasicLayout
      header={t('header.courses')}
      routes={routes}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      {children}
    </BasicLayout>
  )
}
export default CoursesView
