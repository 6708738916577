import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

export const classesFiltersSchema = z.object({
  id: z.array(z.string()).optional(),
  year: z.array(z.string()).optional(),
  tutorId: z.array(z.string()).optional(),
  studentId: z.array(z.string()).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type ClassesFiltersKey = keyof z.infer<typeof classesFiltersSchema>
