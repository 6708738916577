import { createFileRoute, redirect } from '@tanstack/react-router'
import { endOfISOWeek, format, startOfISOWeek } from 'date-fns'

import { API_DATE_FORMAT } from '@/constants/date-format'

export const Route = createFileRoute('/')({
  beforeLoad: () => {
    const today = new Date()
    const start = format(startOfISOWeek(today), API_DATE_FORMAT)

    const end = format(endOfISOWeek(today), API_DATE_FORMAT)
    throw redirect({
      to: '/timetable',
      replace: true,
      search: { dateFrom: start, dateTo: end }
    })
  }
})
