import clsx from 'clsx'

import styles from './Card.module.scss'
import Loading from './Loading'
import Section from './Section'

export type CardColor = 'white' | 'grey'

type CardProps = {
  title?: string
  tooltip?: string
  spinning?: boolean
  color?: CardColor
  withoutBoxShadow?: boolean
  dataTestId?: string
  children?: React.ReactNode
  className?: string
}

const Card = (props: CardProps) => {
  const { color = 'white' } = props
  return (
    <div
      className={clsx(
        styles.card,
        color === 'grey' && styles.grey,
        props.withoutBoxShadow && styles.withoutBoxShadow,
        props.className
      )}
      data-test-id={props.dataTestId}
    >
      <Loading spinning={props.spinning}>
        {props.title ? (
          <Section title={props.title} tooltip={props.tooltip}>
            {props.children}
          </Section>
        ) : (
          props.children
        )}
      </Loading>
    </div>
  )
}

export default Card
