import { useEffect, useState } from 'react'

import type { ToastProps } from '@/components/Toast/Toast'

type State = {
  toasts: ToastProps[]
}

type Toast = Omit<ToastProps, 'id'>

const TOAST_LIMIT = 3
let count = 0

function generateId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER
  return count.toString()
}

const listeners: ((state: State) => void)[] = []
let memoryState: State = { toasts: [] }

function toast(props: Toast) {
  const toastObject = {
    ...props,
    id: generateId(),
    open: true
  }

  memoryState = {
    ...memoryState,
    toasts: [toastObject, ...memoryState.toasts].slice(0, TOAST_LIMIT)
  }

  listeners.forEach(listener => {
    listener(memoryState)
  })
}

function useToast() {
  const [state, setState] = useState<State>(memoryState)

  useEffect(() => {
    listeners.push(setState)

    return () => {
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }, [state])

  return {
    ...state,
    toast
  }
}

export { useToast, toast }
