import { httpService } from '@/api/http.service'
import { clientErrorsMapper } from '@/constants/invalid-status-error'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

const useActivateParent = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { id: string }) =>
      httpService.panel.panelParentsUnblockCreate(data.id),
    onSuccess,
    onError,
    clientErrorsMapper: clientErrorsMapper()
  })

export default useActivateParent
