import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import LinkText from '@/components/LinkText/LinkText'
import Table, { type Column } from '@/components/Table/Table'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import useClasses from '@/queries/useClasses'
import useStudents from '@/queries/useStudents'
import useTeachers from '@/queries/useTeachers'
import useYears from '@/queries/useYears'

import type { ClassesFiltersKey } from '../constants/classes-filters'
import useStudentClasses, { type Class } from '../queries/useStudentClasses'

const routeApi = getRouteApi('/_auth/students-and-classes/classes')

const ClassesView = () => {
  const { t } = useTranslation(['classes'])
  const { page, pageSize, id, year, tutorId, studentId } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const { data: classes, isLoading: isClassesLoading } = useStudentClasses({
    id,
    year,
    tutorId,
    studentId,
    page,
    pageSize
  })

  const tableColumns: Column<Class>[] = [
    {
      key: 'name',
      width: 150,
      title: t('header.class'),
      cellDataTestId: 'class',
      render: (value: Class) => (
        <LinkText
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: value.id
          }}
        >
          {value.name}
        </LinkText>
      )
    },
    {
      dataIndex: 'year',
      key: 'year',
      cellDataTestId: 'year',
      title: t('header.year'),
      width: 100
    },

    {
      dataIndex: ['tutor', 'fullName'],
      key: 'tutor',
      cellDataTestId: 'tutor',
      title: t('header.tutor'),
      width: 200
    },
    {
      dataIndex: 'numberOfStudents',
      key: 'numberOfStudents',
      cellDataTestId: 'number-of-students',
      title: t('header.number-of-students'),
      width: 150
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const { data: classesOptions, isLoading: isClassesOptionsLoading } =
    useClasses()
  const { data: years, isLoading: isYearsLoading } = useYears()
  const { data: students, isLoading: isStudentsLoading } = useStudents()
  const { data: teachers, isLoading: isTeachersLoading } = useTeachers({
    onlyTutor: true
  })

  const changeFilter = (key: ClassesFiltersKey, value?: string | string[]) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {}
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.class'),
      variant: 'multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        loading: isClassesOptionsLoading,
        options: classesOptions?.options || [],
        value: id,
        placeholder: t('label.class'),
        onChange: value => changeFilter('id', value)
      }
    },

    {
      label: t('label.academic-year'),
      variant: 'multiselect',
      filterProps: {
        id: 'academic-year',
        dataTestId: 'academic-year-filter',
        multiple: true,
        loading: isYearsLoading,
        options: years?.options || [],
        value: year,
        placeholder: t('label.academic-year'),
        onChange: value => changeFilter('year', value)
      }
    },
    {
      label: t('label.tutor'),
      variant: 'multiselect',
      filterProps: {
        id: 'tutorId',
        dataTestId: 'tutor-filter',
        multiple: true,
        loading: isTeachersLoading,
        options: teachers?.options || [],
        value: tutorId,
        placeholder: t('label.tutor'),
        onChange: value => changeFilter('tutorId', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        loading: isStudentsLoading,
        options: students?.options || [],
        value: studentId,
        placeholder: t('label.student'),
        onChange: value => changeFilter('studentId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(filter => filter.filterProps.value)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        isLoading={isClassesLoading}
        id="classes-table"
        data={classes?.list || []}
        columns={tableColumns}
        pagination={{
          count: classes?.count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
      />
    </>
  )
}

export default ClassesView
