import { getRouteApi } from '@tanstack/react-router'
import { Trans, useTranslation } from 'react-i18next'

import LeftIcon from '@/assets/icons/chevron-small-left.svg?react'
import Button from '@/components/Button/Button'
import { toast } from '@/hooks/useToast'

import styles from './ResetPasswordView.module.scss'
import Layout from '../components/Layout'
import Resend from '../components/Resend'
import ResetPasswordForm from '../components/ResetPasswordForm'
import useResetPassword from '../mutations/useResetPassword'

const routeApi = getRouteApi('/reset-password')

const BackToLoginButton = () => {
  const { t } = useTranslation(['auth'])
  const search = routeApi.useSearch()

  return (
    <Button
      asLink
      to="/login"
      search={{ redirect: search.redirect }}
      className={styles.link}
      variant="tertiary"
      block
    >
      <LeftIcon className={styles.icon} />
      {t('button.back-to-login')}
    </Button>
  )
}

const ResetPasswordView = () => {
  const { t } = useTranslation(['auth'])
  const {
    mutate: resetPassword,
    formErrors,
    isPending,
    isSuccess,
    variables
  } = useResetPassword()

  const { mutate: resendResetLink, isPending: isResetLinkPending } =
    useResetPassword({
      onSuccess: () =>
        toast({
          title: t('notification.link-to-reset-password-send'),
          variant: 'success'
        })
    })

  return isSuccess && variables ? (
    <Layout
      header={t('header.check-your-email')}
      subheader={
        <Trans
          t={t}
          i18nKey="text.email-to-reset-password-send"
          values={{
            EMAIL: variables.email
          }}
        />
      }
    >
      <BackToLoginButton />

      <Resend
        copyText={t('text.not-receive-email')}
        onResend={() => resendResetLink({ email: variables.email })}
        loading={isResetLinkPending}
      />
    </Layout>
  ) : (
    <Layout
      header={t('header.reset-password')}
      subheader={t('text.enter-email-to-reset-password')}
    >
      <ResetPasswordForm
        errors={formErrors}
        onSubmit={resetPassword}
        loading={isPending}
      />
      <BackToLoginButton />
    </Layout>
  )
}
export default ResetPasswordView
