import * as Label from '@radix-ui/react-label'
import * as RadixSwitch from '@radix-ui/react-switch'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'

import styles from './Switch.module.scss'
import type { FormFieldType } from '../../types/form-field-type'

type SwitchProps = {
  dataTestId?: string
  disabled?: boolean
  label: string
  labelClassName?: string
  variant?: 'default' | 'header'
  hideLabel?: boolean
} & FormFieldType<boolean>

const Switch = (props: SwitchProps) => {
  const { variant = 'default' } = props

  return (
    <div
      className={clsx(
        styles.wrapper,
        props.disabled && styles.wrapperDisabled,
        props.value && styles.wrapperChecked,
        variant === 'header' && styles.wrapperHeader
      )}
      data-test-id={props.dataTestId}
    >
      <RadixSwitch.Root
        id={props.id}
        checked={props.value}
        disabled={props.disabled}
        className={styles.switch}
        onCheckedChange={value => props.onChange?.(value)}
        aria-label={props.label}
        aria-describedby={props.describedby}
        aria-invalid={props.invalid}
        aria-required={props.required}
        aria-disabled={props.disabled}
      >
        <RadixSwitch.Thumb className={styles.indicator} />
      </RadixSwitch.Root>

      <Label.Root className={styles.label} htmlFor={props.id}>
        {props.hideLabel ? (
          <VisuallyHidden.Root>{props.label}</VisuallyHidden.Root>
        ) : (
          <span className={props.labelClassName}>{props.label}</span>
        )}
      </Label.Root>
    </div>
  )
}
export default Switch
