import * as RadixLabel from '@radix-ui/react-label'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import React from 'react'

import styles from './Label.module.scss'

type LabelProps = {
  id: string
  label: React.ReactNode
  hidden?: boolean
  className?: string
  children?: React.ReactNode
}

const Label = (props: LabelProps) => (
  <div className={clsx(styles.container, props.className)}>
    <RadixLabel.Root
      className={clsx(styles.label, !props.hidden && styles.withPadding)}
      htmlFor={props.id}
    >
      {props.hidden ? (
        <VisuallyHidden.Root>{props.label}</VisuallyHidden.Root>
      ) : (
        props.label
      )}
    </RadixLabel.Root>

    {props.children}
  </div>
)

export default Label
