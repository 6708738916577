import { getRouteApi } from '@tanstack/react-router'

import Filters from '@/components/common/Filters/Filters'

import CoursesList from '../components/CoursesList'
import type { CoursesFiltersKey } from '../constants/courses-filters'
import useFilters from '../hookes/useFilters'
import useCoursesGroups from '../queries/useCoursesGroups'

const routeApi = getRouteApi('/_auth/users/$userId/courses')

const UserCoursesView = () => {
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const params = routeApi.useParams()

  const { data: groups, isLoading: isGroupsLoading } = useCoursesGroups({
    ...search,
    teacherOrCoTeacherId: params.userId
  })

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleChangeFilter = (
    key: CoursesFiltersKey,
    value?: string | string[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester: search.semester
      }
    })
  }

  const handleChangeSemester = (semesterId?: string) => {
    navigate({
      search: {
        semester: semesterId
      }
    })
  }

  const { filters, isSomeFilterSelected } = useFilters({
    ...search,
    onChangeFilter: handleChangeFilter,
    onChangeSemester: handleChangeSemester,
    teacherOrCoTeacherId: params.userId
  })

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <CoursesList
        page={search.page}
        pageSize={search.pageSize}
        semesterId={search.semester}
        list={groups?.list || []}
        count={groups?.count || 0}
        loading={isGroupsLoading}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
    </>
  )
}
export default UserCoursesView
