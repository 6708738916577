import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import type { DropdownMenuItemProps as DropdownMenuItemRadixProps } from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import React from 'react'

import styles from './Dropdown.module.scss'

type DropdownProps = {
  trigger: React.ReactNode
  className?: string
  children: React.ReactNode
  open?: boolean
  sideOffset?: number
  onOpenChange?: (value: boolean) => void
  onContentCloseAutoFocus?: (event: Event) => void
  contentHidden?: boolean
}

const Dropdown = (props: DropdownProps) => (
  <DropdownMenu.Root open={props.open} onOpenChange={props.onOpenChange}>
    <DropdownMenu.Trigger asChild>{props.trigger}</DropdownMenu.Trigger>

    <DropdownMenu.Portal>
      <DropdownMenu.Content
        onInteractOutside={() => {
          props.onOpenChange?.(false)
        }}
        className={clsx(styles.container, props.contentHidden && styles.hidden)}
        sideOffset={props.sideOffset || 4}
        onCloseAutoFocus={props.onContentCloseAutoFocus}
        hidden={props.contentHidden}
      >
        {props.children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
)

type DropdownMenuItemProps = DropdownMenuItemRadixProps & {
  variant?: 'neutral' | 'danger'
}

export const DropdownMenuItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps
>((props: DropdownMenuItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { variant, children, ...innerProps } = props
  return (
    <DropdownMenu.DropdownMenuItem
      ref={ref}
      className={clsx(styles.item, variant === 'danger' && styles.itemDanger)}
      {...innerProps}
    >
      {children}
    </DropdownMenu.DropdownMenuItem>
  )
})

DropdownMenuItem.displayName = 'DropdownMenuItem'

export default Dropdown
