import { z } from 'zod'

import { requiredString } from '@/utils/zod'

export const gradeItemForm = () =>
  z.object({
    grade: requiredString().nullable()
  })

export type GradeItemPayload = z.infer<ReturnType<typeof gradeItemForm>>

export type GradeItem = GradeItemPayload & { id: string }
