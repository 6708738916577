import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string().min(1)
  })
)

type Course = {
  id: string
  name: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Course[] =>
  response.map(item => ({
    id: item.id,
    name: item.name
  }))

const useCourses = () =>
  useQuery({
    queryKey: ['panelCoursesList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelCoursesList({
        page_size: 'max',
        fetchKeys: {
          id: true,
          name: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))

      return {
        options: parsed.map(course => ({
          label: course.name,
          value: course.id
        })),
        getCourseName: (id: string) => parsed.find(course => course.id === id)
      }
    }
  })

export default useCourses
