import clsx from 'clsx'
import React, { useRef } from 'react'

import useStickyElement, { PRIORITY } from '@/hooks/useStickyElement'

import styles from './Header.module.scss'

type HeaderProps = {
  navbar: React.ReactNode
  children: React.ReactNode
  tabs: React.ReactNode
}

const Header = (props: HeaderProps) => {
  const navRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const { top: navBarTop } = useStickyElement(navRef, PRIORITY.navbar)

  const { top: footerTop } = useStickyElement(footerRef, PRIORITY.tabs)

  return (
    <>
      <div className={styles.navbar} ref={navRef} style={{ top: navBarTop }}>
        {props.navbar}
      </div>
      <div
        className={clsx(styles.content, props.tabs && styles.contentWithTabs)}
      >
        {props.children}
      </div>
      <div className={styles.footer} ref={footerRef} style={{ top: footerTop }}>
        {props.tabs}
      </div>
    </>
  )
}

export default Header
