import { millisecondsToHours } from 'date-fns'
import { useEffect, useState } from 'react'

const useCountdown = (targetDate = 0, onClearDate?: () => void) => {
  const [countDown, setCountDown] = useState(0)

  useEffect(() => {
    if (targetDate - new Date().getTime() < 0) return

    const interval = setInterval(() => {
      setCountDown(targetDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [targetDate])

  const days = Math.floor(millisecondsToHours(countDown) / 24)
  const hours = millisecondsToHours(countDown) % 24
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  if (targetDate && countDown < 0) {
    onClearDate?.()
  }

  const timeText =
    `${minutes}`.padStart(2, '0') + ':' + `${seconds}`.padStart(2, '0')

  return targetDate && countDown > 0
    ? { days, hours, minutes, seconds, isCounting: true, timeText }
    : { days: 0, hours: 0, minutes: 0, seconds: 0, isCounting: false, timeText }
}

export { useCountdown }
