import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.object({
  name: z.string(),
  id: z.string().min(1),
  assignment_id: z.string().nullable()
})

type Column = {
  name: string
  id: string
  assignmentId: string | null
}

const parseResponse = (response: z.infer<typeof responseSchema>): Column => ({
  name: response.name,
  id: response.id,
  assignmentId: response.assignment_id
})

const useGradesColumn = ({
  columnId,
  enabled
}: {
  columnId?: string
  enabled?: boolean
}) =>
  useQuery({
    queryKey: ['panelGradesRetrieve', columnId],
    queryFn: async () => {
      if (!columnId) throw new Error('Invalid column id')
      const response = await httpService.panel.panelGradesRetrieve(columnId)
      return parseResponse(responseSchema.parse(response))
    },
    enabled
  })

export default useGradesColumn
