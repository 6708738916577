import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  description: z.string(),
  created: z.string().datetime({ offset: true }),
  due_date: z.string().datetime({ offset: true }),
  send_to_students: z.boolean(),
  is_graded: z.boolean(),
  student_items: z.array(
    z.object({
      id: z.string().min(1),
      student: z.object({
        id: z.string().min(1),
        name: z.string().min(1),
        first_name: z.string().min(1),
        last_name: z.string().min(1)
      }),
      grade: z
        .object({
          id: z.string(),
          mark_id: z.string().nullable()
        })
        .nullable()
    })
  )
})

export type Assignment = {
  id: string
  name: string
  created: Date
  dueDate: Date
  description: string
  sendToStudents: boolean
  isGraded: boolean
  students: {
    assignmentId: string
    studentId: string
    name: string
    firstName: string
    lastName: string
    grade: { id: string; markId: string | null } | null
  }[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): Assignment => ({
  id: response.id,
  name: response.name,
  description: response.description,
  created: new Date(response.created),
  dueDate: new Date(response.due_date),
  sendToStudents: response.send_to_students,
  isGraded: response.is_graded,
  students: response.student_items.map(studentItem => ({
    assignmentId: studentItem.id,
    studentId: studentItem.student.id,
    name: studentItem.student.name,
    firstName: studentItem.student.first_name,
    lastName: studentItem.student.last_name,
    grade: studentItem.grade
      ? { id: studentItem.grade.id, markId: studentItem.grade.mark_id }
      : null
  }))
})

const useAssignment = ({ id, enabled }: { id?: string; enabled: boolean }) =>
  useQuery({
    queryKey: ['panelAssignment', id],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!id) return
      const response = await httpService.panel.panelAssignmentsRetrieve(id)

      const parsed = parseResponse(responseSchema.parse(response))
      return parsed
    },
    enabled: enabled && !!id
  })

export default useAssignment
