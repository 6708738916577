import clsx from 'clsx'

import CheckCircle from '@/assets/icons/check-circle.svg?react'
import ErrorCircle from '@/assets/icons/error-circle.svg?react'
import InfoCircle from '@/assets/icons/info-circle.svg?react'
import WarningCircle from '@/assets/icons/warning-circle.svg?react'

import styles from './Alert.module.scss'

type AlertProps = {
  message: React.ReactNode
  description?: React.ReactNode
  className?: string
  dataTestId?: string
  variant?: 'info' | 'error' | 'success' | 'warning'
  withoutBackground?: boolean
}

const Alert = (props: AlertProps) => {
  const { variant = 'info' } = props

  const icon = () => {
    switch (variant) {
      case 'info':
        return <InfoCircle className={styles.icon} />
      case 'error':
        return <ErrorCircle className={styles.icon} />

      case 'success':
        return <CheckCircle className={styles.icon} />
      case 'warning':
        return <WarningCircle className={styles.icon} />
    }
  }

  return (
    <div
      className={clsx(
        styles.alert,
        styles[variant],
        props.description && styles.withDescription,
        props.className,
        props.withoutBackground && styles.withoutBackground
      )}
      data-test-id={props.dataTestId}
    >
      {icon()}
      <div>
        <span
          className={clsx(
            styles.message,
            props.description && styles.messageWithDescription,
            !props.description && styles.messageWithoutDescription
          )}
        >
          {props.message}
        </span>
        {props.description ? (
          <span className={styles.description}>{props.description}</span>
        ) : null}
      </div>
    </div>
  )
}

export default Alert
