import { useQuery } from '@tanstack/react-query'
import type { z } from 'zod'

import { httpService } from '@/api/http.service'
import { addUnassignedFilterOption } from '@/utils/add-unassigned-filter-option'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = arrayOfOptions

type Year = {
  value: string
  label: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Year[] =>
  response.map(item => ({
    value: item.value,
    label: item.label
  }))

const useYears = () =>
  useQuery({
    queryKey: ['panelStudentsYearsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsYearsList()
      const parsedYears = parseResponse(responseSchema.parse(response))

      return {
        options: parsedYears,
        optionsWithUnassigned: addUnassignedFilterOption(parsedYears)
      }
    }
  })

export default useYears
