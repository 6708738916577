import { createFileRoute } from '@tanstack/react-router'

import { ClassGradesView, groupGradesFilters } from '@/modules/classes'

export const Route = createFileRoute(
  '/_auth/students-and-classes/classes/$classId/grades'
)({
  validateSearch: groupGradesFilters,
  component: ClassGradesView
})
