import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MoreOutlined from '@/assets/icons/menu-vertical.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import Drawer from '@/components/Drawer/Drawer'

import styles from './ActionsDrawer.module.scss'

export type DrawerAction = {
  icon: React.ReactNode
  text: string
  onClick: () => void
  isDanger?: boolean
  hidden?: boolean
}

type ActionsDrawerProps = {
  actions: DrawerAction[]
  onlyMobile?: boolean
  triggerButton: { size: 'small' | 'medium'; block?: boolean }
}

const ActionsDrawer = (props: ActionsDrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { t } = useTranslation('common')

  const buttonProps = {
    variant: 'secondary',
    className: clsx(props.onlyMobile && styles.drawerTrigger),
    size: props.triggerButton.size
  } as const

  return props.actions.length ? (
    <Drawer
      open={isDrawerOpen}
      onOpenChange={setIsDrawerOpen}
      trigger={
        props.triggerButton.block ? (
          <Button {...buttonProps} block icon={<MoreOutlined />}>
            {t('button.actions')}
          </Button>
        ) : (
          <ButtonIcon {...buttonProps}>
            <MoreOutlined />
          </ButtonIcon>
        )
      }
    >
      <ul>
        {props.actions
          .filter(action => !action.hidden)
          .map((action, index) => (
            <li
              tabIndex={0}
              role="button"
              key={index}
              className={clsx(
                styles.action,
                action.isDanger && styles.actionDanger
              )}
              onClick={() => {
                setIsDrawerOpen(false)
                action.onClick()
              }}
            >
              <span className={styles.icon}>{action.icon}</span>
              <span>{action.text}</span>
            </li>
          ))}
      </ul>
    </Drawer>
  ) : null
}

export default ActionsDrawer
