import { getRouteApi, Outlet } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Cancel from '@/assets/icons/cancel.svg?react'
import SendLink from '@/assets/icons/send-link.svg?react'
import Unlock from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'
import { type Route } from '@/components/Tabs/Tabs'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import useSemesters from '@/queries/useSemesters'
import useAuthStore from '@/store/useAuthStore'
import type { RouteWithHasPermission } from '@/types/route'
import { getStatusTagColor } from '@/utils/getStatusTagColor'

import styles from './UserView.module.scss'
import useActivateUser from '../mutations/useActivateUser'
import useBlockUser from '../mutations/useBlockUser'
import useSendActivationUserLink, {
  type ActivateLinkResponse
} from '../mutations/useSendUserActivationLink'
import useUser from '../queries/useUser'
import { getColorByProfile, getLabelByProfile } from '../utils/getProfileTag'
import { getUserStatusTooltip } from '../utils/getUserStatusTooltip'

const routeApi = getRouteApi('/_auth/users/$userId')

const UserView = () => {
  const { t } = useTranslation(['users', 'common'])

  const { user: userStore } = useAuthStore()

  const { userId } = routeApi.useParams()

  const { data: user, refetch } = useUser(userId)
  const { data: semesters } = useSemesters()

  const [openActivationLinkModal, setOpenActivationLinkModal] = useState(false)
  const [openBlockConfirmModal, setOpenBlockConfirmModal] = useState(false)
  const [openActivateConfirmModal, setOpenActivateConfirmModal] =
    useState(false)

  const handleBlock = () => {
    if (!!user) blockUser({ id: user.id })
  }

  const handleActivate = () => {
    if (!!user) activateUser({ id: user.id })
  }

  const closeBlockConfirmModal = () => {
    refetch()
    setOpenBlockConfirmModal(false)
  }

  const { mutate: blockUser, isPending: isBlockUserLoading } = useBlockUser({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.user-blocked', { NAME: user?.name })
      })
      closeBlockConfirmModal()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.user-blocked-failed', {
          NAME: user?.name
        })
      })
      closeBlockConfirmModal()
    }
  })

  const closeActivateConfirmModal = () => {
    refetch()
    setOpenActivateConfirmModal(false)
  }

  const { mutate: activateUser, isPending: isActivateUserLoading } =
    useActivateUser({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.user-activate', {
            NAME: user?.name
          })
        })
        closeActivateConfirmModal()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.user-activate', {
            NAME: user?.name
          })
        })
        closeActivateConfirmModal()
      }
    })

  const { isPending: isSendActivationLinkLoading, mutate: sendActivationLink } =
    useSendActivationUserLink({
      onSuccess: (data?: ActivateLinkResponse) => {
        if (data?.successfullySent) {
          if (user?.id) {
            toast({
              variant: 'success',
              title: t('toast.successfully-send-activation-link')
            })
          } else {
            toast({
              variant: 'success',
              title: t('toast.successfully-send-activation-links', {
                NUMBER: data?.successfullySent
              })
            })
          }
        }
        if (data?.unsuccessfullySent) {
          toast({
            variant: 'error',
            title: t('toast.failed-send-activation-links', {
              NUMBER: data.unsuccessfullySent
            })
          })
        }
        refetch()
        setOpenActivationLinkModal(false)
      }
    })

  const handleSendActivationLink = () => {
    if (!user?.id) return
    const ids = [user.id]

    sendActivationLink({
      ids
    })
  }

  const routes: RouteWithHasPermission[] = [
    {
      title: t('navigation.user-details', { ns: 'common' }),
      link: {
        to: '/users/$userId/details'
      },
      hasPermission: true
    },
    {
      title: t('navigation.courses', { ns: 'common' }),
      link: {
        to: '/users/$userId/courses',
        search: {
          semester: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission:
        (userStore?.id === userId || userStore?.isSuperAdmin) &&
        user?.profiles.includes('Teacher')
    }
  ]

  const routesWithPermission: Route[] = routes
    .filter(route => route.hasPermission)
    .map(route => ({
      title: route.title,
      link: route.link
    }))

  const actions = userStore?.isSuperAdmin
    ? [
        {
          icon: <SendLink />,
          onClick: handleSendActivationLink,
          loading: isSendActivationLinkLoading,
          text:
            user?.status === 'active'
              ? t('button.resend-activation-link')
              : t('button.send-activation-link'),
          hidden: user?.status === 'blocked'
        },
        {
          icon: <Unlock />,
          onClick: () => {
            setOpenActivateConfirmModal(true)
          },
          loading: isActivateUserLoading,
          text: t('button.activate-user'),
          hidden: user?.status === 'active' || user?.status === 'inactive'
        },
        {
          icon: <Cancel />,
          onClick: () => {
            setOpenBlockConfirmModal(true)
          },
          loading: isBlockUserLoading,
          text: t('button.block-user'),
          variantAction: 'danger' as const,
          hidden: user?.status === 'blocked' || user?.status === 'inactive'
        }
      ]
    : []

  return (
    <BasicLayout
      routes={routesWithPermission}
      actions={actions}
      moduleName={t('header.administration', { ns: 'common' })}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{user?.name}</h1>
          <div className={styles.box}>
            {user?.status ? (
              <Tooltip
                trigger={
                  <Tag
                    label={user.status}
                    color={getStatusTagColor(user.status)}
                    dataTestId="status-tag"
                  />
                }
                text={getUserStatusTooltip(user.status)}
              />
            ) : null}

            <div className={styles.profiles}>
              <span className={styles.role}>Role:</span>
              <div className={styles.profilesWrapper}>
                {!user?.profiles.length ? (
                  <Tag
                    variant="user-type"
                    className={styles.profileTag}
                    label={t('text.user')}
                    color={getColorByProfile('User')}
                  />
                ) : (
                  user.profiles.map((userProfile, index) => (
                    <Tag
                      variant="user-type"
                      className={styles.profileTag}
                      key={index}
                      label={getLabelByProfile(userProfile)}
                      color={getColorByProfile(userProfile)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      }
    >
      <Outlet />

      <ConfirmModal
        id="activation-link-modal"
        header={t('header.send-activation-link')}
        subheader={t('help.send-activation-link-single', {
          NAME: user?.name
        })}
        confirmButton={
          <Button
            variant="primary"
            onClick={handleSendActivationLink}
            loading={isSendActivationLinkLoading}
          >
            {t('button.send')}
          </Button>
        }
        open={openActivationLinkModal}
        onOpenChange={setOpenActivationLinkModal}
      />

      <ConfirmModal
        id="blocked-modal"
        header={t('header.block-user')}
        subheader={t('help.want-to-block-user', {
          NAME: user?.name
        })}
        confirmButton={
          <Button
            variant="danger"
            onClick={handleBlock}
            loading={isBlockUserLoading}
          >
            {t('button.block')}
          </Button>
        }
        open={openBlockConfirmModal}
        onOpenChange={setOpenBlockConfirmModal}
      />

      <ConfirmModal
        id="activate-modal"
        header={t('header.activate-user')}
        subheader={t('help.want-to-activate-user', {
          NAME: user?.name
        })}
        confirmButton={
          <Button onClick={handleActivate} loading={isActivateUserLoading}>
            {t('button.activate')}
          </Button>
        }
        open={openActivateConfirmModal}
        onOpenChange={setOpenActivateConfirmModal}
      />
    </BasicLayout>
  )
}

export default UserView
