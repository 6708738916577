import { isValid, parse } from 'date-fns'

import { DATE_FORMAT, TIME_FORMAT } from '../constants/date-format'

export const parseDate = (date?: string) => {
  if (!date) return null
  const parsedDate = parse(date, DATE_FORMAT, new Date())
  if (!isValid(parsedDate)) return null
  return parsedDate
}

export const parseTime = (time?: string) => {
  if (!time) return null
  const parsedDate = parse(time, TIME_FORMAT, new Date())
  if (!isValid(parsedDate)) return null
  return parsedDate
}
