import clsx from 'clsx'

import styles from './DataBlock.module.scss'
import TextWithLabel, {
  type TextWithLabelSize
} from '../TextWithLabel/TextWithLabel'

type DataBlockProps = {
  header: React.ReactNode
  headerVariant?: 'small' | 'normal'
  size?: TextWithLabelSize
  infoArray: {
    label?: string
    value: React.ReactNode | string[]
    dataTestId?: string
  }[]
}

const DataBlock = (props: DataBlockProps) => {
  const { headerVariant = 'normal', size = 'small' } = props
  return (
    <div>
      <p className={clsx(styles.header, styles[headerVariant])}>
        {props.header}
      </p>

      {props.infoArray.map((item, index) => (
        <TextWithLabel
          label={item.label}
          text={item.value}
          key={index}
          dataTestId={item.dataTestId}
          variant="horizontal-full-row"
          size={size}
        />
      ))}
    </div>
  )
}
export default DataBlock
