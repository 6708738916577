import { useMediaQuery } from 'usehooks-ts'

const useScreenResolution = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isTablet = useMediaQuery('(max-width: 1170px)')

  return { isMobile, isTablet }
}

export { useScreenResolution }
