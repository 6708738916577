import clsx from 'clsx'
import { add } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import { useCountdown } from '@/hooks/useCountDown'

import styles from './ResendCode.module.scss'

const RESEND_COOLDOWN = 30

type ResendCodeProps = {
  copyText: string
  onResend: () => void
  loading?: boolean
  disabled?: boolean
}

export const ResendCode = (props: ResendCodeProps) => {
  const { t } = useTranslation(['auth'])

  const [nextResendAvailable, setNextResendAvailable] = useState(
    new Date().getTime()
  )

  const resendCountdown = useCountdown(nextResendAvailable)

  const onResend = () => {
    setNextResendAvailable(
      add(new Date(), { seconds: RESEND_COOLDOWN }).getTime()
    )
    if (nextResendAvailable < new Date().getTime()) props.onResend?.()
  }

  return (
    <div className={styles.box}>
      <p className={clsx('body-regular-3')}>{props.copyText}</p>
      <Button
        className={styles.resendButton}
        variant="tertiary"
        size="small"
        type="button"
        loading={props.loading}
        disabled={!!resendCountdown.isCounting || props.disabled}
        onClick={onResend}
      >
        {resendCountdown.isCounting
          ? t('error.resend-blocked', { TIME: resendCountdown.timeText })
          : t('button.resend')}
      </Button>
    </div>
  )
}

export default ResendCode
