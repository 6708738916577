import { useTranslation } from 'react-i18next'

import type { Filter } from '@/components/common/Filters/Filters'
import useSemesters from '@/queries/useSemesters'

import { type CoursesFiltersKey } from '../constants/courses-filters'
import useCoursesFilters from '../queries/useCoursesFilters'

type CoursesFiltersProps = {
  semester?: string
  tutotId?: string
  teacherOrCoTeacherId?: string
  studentsId?: string[]
  coursesId?: string[]
  ids?: string[]
  teachersId?: string[]
  coTeachersId?: string[]
  classesId?: string[]
  onChangeFilter: (key: CoursesFiltersKey, value?: string | string[]) => void
  onChangeSemester: (semester?: string) => void
}

const useFilters = (props: CoursesFiltersProps) => {
  const { t } = useTranslation(['courses'])
  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const { data: filtersOptions, isLoading: isFiltersPending } =
    useCoursesFilters({
      semester: props.semester,
      tutorId: props.tutotId,
      teacherOrCoTeacherId: props.teacherOrCoTeacherId
    })

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        dataTestId: 'semester-filter',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: props.semester,
        placeholder: t('label.semester'),
        onChange: value => props.onChangeSemester(value)
      }
    },
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        dataTestId: 'course-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.coursesOptions || [],
        value: props.coursesId,
        placeholder: t('label.course'),
        onChange: value => props.onChangeFilter('coursesId', value)
      }
    },
    {
      label: t('label.group'),
      variant: 'multiselect',
      filterProps: {
        id: 'group',
        dataTestId: 'group-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.groupsOptions || [],
        value: props.ids,
        placeholder: t('label.group'),
        onChange: value => props.onChangeFilter('ids', value)
      }
    },
    {
      label: t('label.teacher'),
      variant: 'multiselect',
      filterProps: {
        id: 'teacher',
        dataTestId: 'teacher-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.teachersOptions || [],
        value: props.teachersId,
        placeholder: t('label.teacher'),
        onChange: value => props.onChangeFilter('teachersId', value)
      }
    },
    {
      label: t('label.co-teacher'),
      variant: 'multiselect',
      filterProps: {
        id: 'co-teacher',
        dataTestId: 'coteacher-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.coTeachersOptions || [],
        value: props.coTeachersId,
        placeholder: t('label.co-teacher'),
        onChange: value => props.onChangeFilter('coTeachersId', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.studentsOptions || [],
        value: props.studentsId,
        placeholder: t('label.student'),
        onChange: value => props.onChangeFilter('studentsId', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        loading: isFiltersPending,
        options: filtersOptions?.classesOptions || [],
        value: props.classesId,
        placeholder: t('label.class'),
        onChange: value => props.onChangeFilter('classesId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter => filter.filterProps.value && filter.filterProps.id !== 'semester'
  )

  return { filters, isSomeFilterSelected }
}

export default useFilters
