import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

type ResetPasswordPayload = {
  email: string
}

const fieldErrorsMapper: FieldErrorsMapper<ResetPasswordPayload> = {
  email: 'email'
}

const useResetPassword = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: ResetPasswordPayload) =>
      httpService.panel.panelAuthNewPasswordCreate(data),
    onSuccess: options?.onSuccess,
    fieldErrorsMapper
  })

export default useResetPassword
