import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import EyeOpen from '@/assets/icons/eye-open.svg?react'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal/Modal'

import styles from './ShowMoreDetails.module.scss'

type LessonDetailsMobileProps = {
  children?: React.ReactNode
  className?: string
}

const LessonDetailsMobile = (props: LessonDetailsMobileProps) => {
  const { t } = useTranslation(['lessonDetails'])
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={props.className}>
      <Modal
        id="lessonDetails"
        title={t('header.lesson-details')}
        open={isOpen}
        onOpenChange={setIsOpen}
        contentClassName={styles.lessonDetailsModalWrapper}
        footer={
          <Button block variant="secondary" onClick={() => setIsOpen(false)}>
            {t('button.cancel')}
          </Button>
        }
        trigger={
          <Button variant="secondary" block icon={<EyeOpen />}>
            {t('button.show-details')}
          </Button>
        }
      >
        <div className={styles.wrapper}>
          <div>{props.children}</div>
        </div>
      </Modal>
    </div>
  )
}
export default LessonDetailsMobile
