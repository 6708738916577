import { useEffect, useId, type RefObject } from 'react'

export const PRIORITY = {
  navbar: 10,
  tabs: 20,
  tableTopBar: 30,
  tableHeader: 40
}

type Element = { id: string; height: number; priority: number }

let stickyElements: Element[] = []

const addStickyElement = (element: Element) => {
  stickyElements.push(element)
  stickyElements = stickyElements.sort((a, b) =>
    a.priority > b.priority ? 1 : -1
  )
}

const updateStickyElement = (editedElement: Element) => {
  stickyElements = stickyElements
    .map(element => (element.id !== editedElement.id ? element : editedElement))
    .sort((a, b) => (a.priority > b.priority ? 1 : -1))
}

const removeStickyElement = (id: string) => {
  stickyElements = stickyElements.filter(element => element.id !== id)
}

const getTopPositionForId = (id: string) => {
  const findIndex = stickyElements.findIndex(element => element.id === id)

  return stickyElements.reduce(
    (prev, curr, index) => (index < findIndex ? prev + curr.height : prev),
    0
  )
}

const useStickyElement = <T extends HTMLElement>(
  elementRef: RefObject<T>,
  elementPriority: number
) => {
  const id = useId()

  useEffect(() => {
    const height = elementRef.current?.getBoundingClientRect().height || 0
    if (!stickyElements.find(el => el.id === id))
      addStickyElement({ id, height, priority: elementPriority })
    else {
      updateStickyElement({ id, height, priority: elementPriority })
    }
    return () => removeStickyElement(id)
  }, [elementPriority, elementRef, id])

  return { top: getTopPositionForId(id) }
}

export default useStickyElement
