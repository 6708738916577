import { createFileRoute } from '@tanstack/react-router'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { AllCoursesView, coursesFiltersSchema } from '@/modules/courses'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/courses/all-courses')({
  validateSearch: search => coursesFiltersSchema.parse(search),
  component: () => <AllCoursesView />,
  loaderDeps: ({ search }) => ({
    ...search
  }),
  loader: async ({ deps: search, context, navigate }) => {
    const semesters =
      await context.queryClient.ensureQueryData(semesterQueryOptions)
    if (!search.semester) {
      navigate({
        replace: true,
        to: '/courses/all-courses',
        search: {
          semester: semesters.currentSemester?.id,
          page: 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      })
    }
  }
})
