import { Link, type LinkProps } from '@tanstack/react-router'
import clsx from 'clsx'

import LinkInternal from '@/assets/icons/link-internal.svg?react'
import type { routeTree } from '@/routeTree.gen'

import styles from './LinkText.module.scss'

type LinkTextProps = Pick<
  LinkProps<typeof routeTree>,
  'to' | 'search' | 'params'
> & {
  className?: string
  dataTestId?: string
  children: React.ReactNode
  size?: 'small' | 'medium'
}

const LinkText = (props: LinkTextProps) => (
  <Link
    to={props.to}
    search={props.search}
    params={props.params}
    className={clsx(
      styles.link,
      props.size === 'medium' ? styles.medium : styles.small,
      props.className
    )}
    data-test-id={props.dataTestId}
  >
    <>
      <LinkInternal className={styles.icon} />
      {props.children}
    </>
  </Link>
)

export default LinkText
