import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/Alert/Alert'
import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import DirtyModal from '@/components/common/DirtyModal'
import { toast } from '@/hooks/useToast'

import styles from './CoverSettingModal.module.scss'
import CoverTeacherField from './CoverTeacherField'
import {
  formSchemaCoverTeacher,
  type CoverTeacherFormSchema
} from '../constants/cover-payload'
import useSetCover from '../mutations/useSetCover'
import useTeachersAvailability from '../queries/useTeachersAvailability'
import type { Lesson } from '../types'
import {
  getLessonFormattedTime,
  getLessonShortedDetails
} from '../utils/lesson-details-format'

type CoverSettingModalProps = {
  courseName: string
  groupName: string
  lesson: Lesson
  open: boolean
  onOpenChange: (open: boolean) => void
  onCoverChange: () => void
}

const CoverSettingModal = (props: CoverSettingModalProps) => {
  const { t } = useTranslation('lessonDetails')

  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState(false)

  const { mutate: setCover } = useSetCover(props.lesson.id, {
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.cover-created-successfully', {
          LESSON: getLessonFormattedTime(props.lesson)
        })
      })
      props.onCoverChange()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.cover-created-failed', {
          LESSON: getLessonFormattedTime(props.lesson)
        })
      })
      props.onOpenChange(false)
    }
  })

  const { teacher, coTeacher } = props.lesson

  const handleOnCancel = () => {
    isDirty ? setIsDirtyModalOpen(true) : handleClose()
  }

  const form = useForm<CoverTeacherFormSchema>({
    resolver: zodResolver(formSchemaCoverTeacher()),
    mode: 'onTouched',
    defaultValues: {
      teacher: undefined,
      coTeacher: undefined
    }
  })

  const { isDirty } = form.formState

  const { data: teachers, isPending: isTeacherOptionsLoading } =
    useTeachersAvailability({
      startDate: props.lesson.startDate,
      endDate: props.lesson.endDate,
      enabled: props.open
    })

  const handleSubmit = () => {
    setCover(form.getValues())
  }

  const handleClose = () => {
    props.onOpenChange(false)
    form.reset()
  }

  return (
    <ModalForm
      id="cover-lesson"
      title={t('header.cover-lesson', { COURSE: props.courseName })}
      description={getLessonShortedDetails(props.lesson)}
      submitText={t('button.cover')}
      form={form}
      size="md"
      open={props.open}
      onOpenChange={props.onOpenChange}
      onSubmit={handleSubmit}
      onCancel={handleOnCancel}
      onClose={handleClose}
    >
      <h4 className={styles.label}>{t('header.cover-for')}</h4>
      <div className={styles.fieldContainer}>
        <span className={styles.teacherlabel}>{t('header.main-teacher')}</span>
        <FormField
          label={t('label.select-teacher')}
          control={form.control}
          id="teacher"
          name="teacher"
          render={({ inputProps }) => (
            <CoverTeacherField
              placeholder={t('help.select-teacher')}
              startDate={props.lesson.startDate}
              endDate={props.lesson.endDate}
              teacher={teacher}
              inputProps={inputProps}
              teacherList={teachers?.list || []}
              options={teachers?.options || []}
              isOptionsLoading={isTeacherOptionsLoading}
            />
          )}
        />
      </div>
      {coTeacher ? (
        <div className={styles.fieldContainer}>
          <span className={styles.teacherlabel}>{t('header.co-teacher')}</span>
          <FormField
            label={t('label.select-co-teacher')}
            control={form.control}
            id="coTeacher"
            name="coTeacher"
            render={({ inputProps }) => (
              <CoverTeacherField
                placeholder={t('help.select-co-teacher')}
                startDate={props.lesson.startDate}
                endDate={props.lesson.endDate}
                teacher={coTeacher}
                inputProps={inputProps}
                teacherList={teachers?.list || []}
                options={teachers?.options || []}
                isOptionsLoading={isTeacherOptionsLoading}
              />
            )}
          />
        </div>
      ) : null}

      {form.formState.errors.root ? (
        <div>{t('error.one-field-is-required')}</div>
      ) : null}
      <Alert variant="info" message={t('help.cover-only-current-lesson')} />

      <DirtyModal
        isOpen={isDirtyModalOpen}
        onOpenChange={setIsDirtyModalOpen}
        onConfirm={() => {
          handleClose()
          setIsDirtyModalOpen(false)
        }}
      />
    </ModalForm>
  )
}

export default CoverSettingModal
