import { createFileRoute, Outlet } from '@tanstack/react-router'

import { StudentView, studentQueryOptions } from '@/modules/students'

export const Route = createFileRoute(
  '/_auth/students-and-classes/students/$studentId'
)({
  component: () => (
    <StudentView>
      <Outlet />
    </StudentView>
  ),
  loader: async ({ context, params, navigate, location }) => {
    const studentDetails = await context.queryClient.ensureQueryData(
      studentQueryOptions(params.studentId)
    )

    if (
      location.pathname === `/students-and-classes/students/${params.studentId}`
    ) {
      navigate({
        to: '/students-and-classes/students/$studentId/details',
        params
      })
    }
    return studentDetails.fullName
  },
  meta: ({ loaderData }) => [{ title: loaderData }]
})
