import type { GlobalError } from 'react-hook-form'

import Alert from '../Alert/Alert'

type NonFieldErrorsProps = {
  error?: GlobalError
  className?: string
  dataTestId?: string
}

const NonFieldErrors = (props: NonFieldErrorsProps) =>
  props.error ? (
    <Alert
      message={props.error.message}
      variant="error"
      className={props.className}
      dataTestId={props.dataTestId}
    />
  ) : null

export default NonFieldErrors
