import type { QueryClient } from '@tanstack/react-query'
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'

import type { AuthStore } from '@/store/useAuthStore'

export const Route = createRootRouteWithContext<{
  authStore: AuthStore | null
  queryClient: QueryClient
}>()({
  component: () => <Outlet />,
  pendingComponent: () => <div>Loading...</div>
})
