import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { toast } from '@/hooks/useToast'

import Layout from '../components/Layout'
import NewPasswordForm, {
  type NewPasswordPayload
} from '../components/NewPasswordForm'
import useNewPassword from '../mutations/useNewPassword'

const routeApi = getRouteApi('/new-password')

const NewPasswordView = () => {
  const { t } = useTranslation(['auth'])

  const params = routeApi.useSearch()
  const navigate = useNavigate()

  const {
    mutate: newPasswordRequest,
    isPending,
    formErrors
  } = useNewPassword({
    onSuccess: () => {
      toast({
        title: t('notification.password-changed'),
        variant: 'success'
      })
      navigate({ to: '/login', search: { redirect: '/' } })
    }
  })

  const handleOnSave = (payload: NewPasswordPayload) => {
    newPasswordRequest({ ...payload, token: params.token })
  }

  return (
    <Layout
      header={t('header.new-password')}
      subheader={t('text.enter-new-password')}
    >
      <NewPasswordForm
        onSubmit={handleOnSave}
        loading={isPending}
        errors={formErrors}
      />
    </Layout>
  )
}

export default NewPasswordView
