import clsx from 'clsx'

import styles from './Badge.module.scss'

export type BadgeColor = 'blue' | 'green' | 'red' | 'orange' | 'gray'

type BadgeProps = {
  color: BadgeColor
  children?: React.ReactNode
  text?: React.ReactNode
}

const Badge = (props: BadgeProps) => (
  <span className={styles.badge}>
    {props.children}
    {props.text ? (
      <div className={clsx(styles.textWrapper, styles[props.color])}>
        {props.text}
      </div>
    ) : (
      <sup className={clsx(styles.dot, styles[props.color])} />
    )}
  </span>
)

export default Badge
