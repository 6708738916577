import { useTranslation } from 'react-i18next'

import ArrowRightIcon from '@/assets/icons/arrow-right.svg?react'
import Alert from '@/components/Alert/Alert'
import { type SelectOption } from '@/components/Select/Select'
import { type FormFieldType } from '@/types/form-field-type'

import styles from './CoverTeacherField.module.scss'
import TeacherSelect from './TeacherSelect'
import useTeacherAvailability from '../queries/useTeacherAvailability'
import { type TeacherAvailability } from '../types'

type CoverTeacherFieldProps = {
  placeholder: string
  startDate: Date
  endDate: Date
  teacher: {
    id: string
    name: string
  }
  inputProps: FormFieldType<string | undefined>
  teacherList: TeacherAvailability[]
  options: SelectOption<string>[]
  isOptionsLoading: boolean
}

const CoverTeacherField = (props: CoverTeacherFieldProps) => {
  const { t } = useTranslation('lessonDetails')

  const teacherOption = props.teacherList?.find(
    teacher => teacher.id === props.inputProps.value
  )

  const { data: teacherAvailability } = useTeacherAvailability({
    startDate: props.startDate,
    endDate: props.endDate,
    teacherId: props.inputProps.value,
    enabled: !!props.inputProps.value && teacherOption?.status !== 'available'
  })

  const options = props.options.filter(
    teacher => teacher.value !== props.teacher.id
  )

  return (
    <div className={styles.coverFieldWrapper}>
      <div className={styles.coverFieldContainer}>
        <div className={styles.currentTeacher}>{props.teacher.name}</div>

        <ArrowRightIcon className={styles.icon} />

        <TeacherSelect
          {...props.inputProps}
          teachersList={props.teacherList}
          isOptionsLoading={props.isOptionsLoading}
          options={options}
          placeholder={props.placeholder}
        />
      </div>

      {teacherAvailability?.status === 'unavailable' ? (
        <Alert
          message={t('help.teacher-is-not-working', {
            TEACHER: teacherOption?.name
          })}
          variant="warning"
        />
      ) : null}

      {teacherAvailability?.status === 'busy' &&
      teacherAvailability.lessons.length ? (
        <div className={styles.alertsContainer}>
          {teacherAvailability.lessons.map(lesson => (
            <Alert
              key={lesson.id}
              message={
                <>
                  {t('help.teacher-is-busy', {
                    TEACHER: teacherOption?.name
                  })}
                  <br />
                  {lesson.course.name}, {lesson.group.name}
                </>
              }
              variant="warning"
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default CoverTeacherField
