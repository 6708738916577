import { createFileRoute } from '@tanstack/react-router'

import { MyClassCoursesView, coursesFiltersSchema } from '@/modules/courses'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/courses/my-class-courses')({
  validateSearch: search => coursesFiltersSchema.parse(search),
  component: () => <MyClassCoursesView />,
  loaderDeps: ({ search }) => ({
    ...search
  }),
  loader: async ({ deps: search, context, navigate }) => {
    const semesters =
      await context.queryClient.ensureQueryData(semesterQueryOptions)
    if (!search.semester) {
      navigate({
        to: '/courses/my-class-courses',
        replace: true,
        search: { ...search, semester: semesters.currentSemester?.id }
      })
    }
  }
})
