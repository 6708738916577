import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    label: z.string().min(1),
    for_behaviour: z.boolean()
  })
)

export type Mark = {
  id: string
  label: string
  isBehaviour: boolean
}

const parseResponse = (response: z.infer<typeof responseSchema>): Mark[] =>
  response.map(mark => ({
    id: mark.id,
    label: mark.label,
    isBehaviour: mark.for_behaviour
  }))

const useMarksList = () => {
  const query = useQuery({
    queryKey: ['panelGradesGradeMarksList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelGradesGradeMarksList({
        page_size: 'max',
        fetchKeys: { id: true, label: true, for_behaviour: true }
      })

      const parsed = parseResponse(responseSchema.parse(response.results))

      return {
        marksList: parsed,
        marksMap: new Map(parsed.map(mark => [mark.id, mark.label])),
        marksOptions: parsed
          .filter(mark => !mark.isBehaviour)
          .map(mark => ({ value: mark.id, label: mark.label })),
        behaviourMarksOptions: parsed
          .filter(mark => mark.isBehaviour)
          .map(mark => ({ value: mark.id, label: mark.label }))
      }
    }
  })

  return query
}

export default useMarksList
