import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

import type { Attendance } from '../types'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    presence: z.union([
      z.literal('present'),
      z.literal('late'),
      z.literal('absent_excused'),
      z.literal('absent_unexcused'),
      z.literal('')
    ]),
    student: z.object({
      id: z.string().min(1),
      name: z.string().min(1),
      first_name: z.string().min(1),
      last_name: z.string().min(1)
    })
  })
)

const parseResponse = (
  response: z.infer<typeof responseSchema>
): Attendance[] =>
  response.map(item => ({
    id: item.id,
    presence: item.presence ? item.presence : null,
    student: {
      id: item.student.id,
      name: item.student.name,
      firstName: item.student.first_name,
      lastName: item.student.last_name
    }
  }))

const useAttendanceList = (lessonId: string) =>
  useQuery({
    queryKey: ['panelAttendancesList', lessonId],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelAttendancesList({
        page_size: 'max',
        lesson_id: lessonId,
        ordering: 'student__last_name,student__first_name'
      })

      return parseResponse(responseSchema.parse(response.results))
    },
    enabled: !!lessonId
  })

export default useAttendanceList
