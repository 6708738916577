import * as ToastPrimitives from '@radix-ui/react-toast'
import clsx from 'clsx'
import React from 'react'

import CheckCircleIcon from '@/assets/icons/check-circle.svg?react'
import ErrorCircleIcon from '@/assets/icons/error-circle.svg?react'
import InfoCircle from '@/assets/icons/info-circle.svg?react'

import styles from './Toast.module.scss'

type TastVariant = 'info' | 'warning' | 'error' | 'success'

export type ToastProps = {
  id: string
  title?: React.ReactNode
  duration?: number
  variant: TastVariant
  type?: 'foreground' | 'background'
  description?: React.ReactNode
  action?: {
    render: React.ReactNode
    altText: string
  }
}

// Workaround to fix accessibility problems
// https://github.com/radix-ui/primitives/issues/1750
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AccessibleRoot = React.forwardRef<any, any>((props, forwardedRef) => (
  <div {...props} ref={forwardedRef}>
    {props.children}
  </div>
))

AccessibleRoot.displayName = 'AccessibleRoot'

const ToastIcon = ({ variant }: { variant: TastVariant }) => {
  switch (variant) {
    case 'info':
    case 'warning':
      return <InfoCircle className={styles.icon} />
    case 'error':
      return <ErrorCircleIcon className={styles.icon} />
    case 'success':
      return <CheckCircleIcon className={styles.icon} />
  }
}

const Toast = (props: ToastProps) => (
  <ToastPrimitives.Root
    asChild
    key={props.id}
    type={props.type}
    className={clsx(styles.toastRoot, styles[props.variant])}
    duration={props.duration}
  >
    <AccessibleRoot>
      <ToastIcon variant={props.variant} />
      <ToastPrimitives.Title className={styles.titleWrapper}>
        <span className={styles.title}>{props.title}</span>
        <span className={styles.description}>{props.description}</span>
      </ToastPrimitives.Title>

      {props.action ? (
        <ToastPrimitives.Action
          className={styles.action}
          asChild
          altText={props.action.altText}
        >
          {props.action.render}
        </ToastPrimitives.Action>
      ) : null}
    </AccessibleRoot>
  </ToastPrimitives.Root>
)

export default Toast
