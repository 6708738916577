import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type {
  GradeItem,
  GradeItemPayload
} from '../constants/grade-item-payload'

const fieldErrorsMapper: FieldErrorsMapper<GradeItemPayload> = {
  mark_id: 'grade',
  non_field_error: 'root'
}

const useEditGradeItem = (options: {
  onSuccess: (variables: GradeItem) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: GradeItem) =>
      httpService.panel.panelGradesGradeItemsUpdate(data.id, {
        mark_id: data.grade
      }),
    fieldErrorsMapper,
    onSuccess: (_, variables) => options.onSuccess(variables)
  })

export default useEditGradeItem
