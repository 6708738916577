import { useEffect, useRef } from 'react'

export default function useRemoveValueFromPasswordInput(
  inputRef: React.RefObject<HTMLInputElement>
) {
  const removePasswordTimeoutRef = useRef<ReturnType<typeof setTimeout>[]>([])

  const removePasswordTimeout = () => {
    removePasswordTimeoutRef.current.push(
      setTimeout(() => {
        if (
          inputRef.current &&
          inputRef.current.getAttribute('type') === 'password' &&
          inputRef.current.hasAttribute('value')
        ) {
          inputRef.current.removeAttribute('value')
        }
      })
    )
  }

  useEffect(
    () => () =>
      removePasswordTimeoutRef.current.forEach(timer => {
        if (timer) {
          clearTimeout(timer)
        }
      }),
    []
  )

  return removePasswordTimeout
}
