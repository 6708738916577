import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'

import type { EditAssignmentPayload } from '../constants/assignment-payload'

const fieldErrorsMapper: FieldErrorsMapper<EditAssignmentPayload> = {
  name: 'name',
  description: 'description',
  due_date: 'dueDate',
  send_to_students: 'sendToStudents',
  is_graded: 'isGraded',
  student_ids: 'studentIds',
  non_field_error: 'root'
}

const useEditAssignment = (options?: {
  onSuccess: (validData: EditAssignmentPayload) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: EditAssignmentPayload) =>
      httpService.panel.panelAssignmentsUpdate(data.id, {
        description: data.description,
        due_date: formatApiDate(data.dueDate),
        is_graded: data.isGraded,
        name: data.name,
        send_to_students: data.sendToStudents,
        student_ids: data.studentIds
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    fieldErrorsMapper
  })

export default useEditAssignment
