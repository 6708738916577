import styles from './BadgeFilter.module.scss'

type BadgeProps = {
  text?: React.ReactNode
}

const Badge = (props: BadgeProps) => (
  <span className={styles.filterBadge}>{props.text}</span>
)

export default Badge
