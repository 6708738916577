import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString()
})

export type Course = {
  id: string
  name: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Course => ({
  id: response.id,
  name: response.name
})

export const courseQueryOptions = (courseId: string) => ({
  queryKey: ['panelCoursesRetrieve', courseId],
  staleTime: 60 * 100,
  queryFn: async () => {
    const courseResponse = await httpService.panel.panelCoursesRetrieve(
      courseId,
      {
        fetchKeys: {
          id: true,
          name: true
        }
      }
    )
    return parseResponse(responseSchema.parse(courseResponse))
  }
})

const useCourse = (courseId: string) => useQuery(courseQueryOptions(courseId))

export default useCourse
