import * as Collapsible from '@radix-ui/react-collapsible'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'
import { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from 'usehooks-ts'

import ChevronSmallDown from '@/assets/icons/chevron-small-down.svg?react'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import i18n from '@/i18n'

import styles from './Navigation.module.scss'

const administrationRoutes = [
  {
    title: i18n.t('navigation.teachers-and-users', { ns: 'common' }),
    path: '/users'
  }
]

const academicsRoutes = [
  {
    title: i18n.t('navigation.timetable', { ns: 'common' }),
    path: '/timetable'
  },
  {
    title: i18n.t('navigation.courses', { ns: 'common' }),
    path: '/courses'
  },
  {
    title: i18n.t('navigation.students-and-classes', { ns: 'common' }),
    path: '/students-and-classes'
  }
]

const settingsRoutes = [
  {
    title: i18n.t('navigation.settings', { ns: 'common' }),
    path: '/profile'
  }
]

const items = [
  {
    name: i18n.t('navigation.academics', { ns: 'common' }),
    routes: academicsRoutes
  },
  {
    name: i18n.t('navigation.administration', { ns: 'common' }),
    routes: administrationRoutes
  },
  {
    name: i18n.t('navigation.school-settings', { ns: 'common' }),
    routes: settingsRoutes
  }
]

const Hamburger = forwardRef(function Hamburger(
  props: { onClick: () => void; isOpen: boolean },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { t } = useTranslation(['common'])
  return (
    <ButtonIcon
      ref={ref}
      className={clsx(styles.hamburger, props.isOpen && styles.open)}
      onClick={() => props.onClick()}
      size="large"
      variant="primary"
      ariaLabel={t('label.open-menu')}
    >
      <span className={clsx(styles.bar, styles.barTop)} aria-hidden="true" />
      <span className={clsx(styles.bar, styles.barMiddle)} aria-hidden="true" />
      <span className={clsx(styles.bar, styles.barBottom)} aria-hidden="true" />
    </ButtonIcon>
  )
})

type NavigationLayoutProps = {
  items: { name: string; routes: { title: string; path: string }[] }[]
}

export const NavigationLayout = (props: NavigationLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const navigationRef = useRef(null)
  const hamburgerRef = useRef(null)

  const handleClickOutside = () => {
    setIsOpen(false)
  }

  useOnClickOutside([navigationRef, hamburgerRef], handleClickOutside)

  return (
    <>
      <div
        className={clsx(styles.mobileHeader, isOpen && styles.mobileHeaderOpen)}
      >
        <Hamburger
          isOpen={isOpen}
          ref={hamburgerRef}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      </div>

      <div
        className={clsx(styles.navigation, isOpen && styles.navigationIsOpen)}
      >
        <div className={styles.navigationHeaderWrapper}>
          <p className={styles.navigationHeader}>
            Kantonsschule am Burggraben St.Gallen
          </p>
        </div>

        <NavigationMenu.Root orientation="horizontal" ref={navigationRef}>
          <NavigationMenu.List>
            {props.items.map((collapse, index) => (
              <li key={index}>
                <Collapsible.Root className={styles.collapse} defaultOpen>
                  <Collapsible.Trigger className={styles.collapseTrigger}>
                    <div className={styles.collapseHeader}>
                      {collapse.name}
                      <ChevronSmallDown className={styles.collapseIcon} />
                    </div>
                  </Collapsible.Trigger>

                  <Collapsible.Content className={styles.collapseContent}>
                    <ul>
                      {collapse.routes.map(item => (
                        <NavigationMenu.Item key={item.path}>
                          <NavigationMenu.Link asChild>
                            <Link
                              to={item.path}
                              className={clsx(styles.navigationLink)}
                              activeProps={{
                                className: styles.navigationLinkActive
                              }}
                              onClick={() => {
                                setIsOpen(false)
                              }}
                            >
                              <div className={styles.linkText}>
                                {item.title}
                              </div>
                            </Link>
                          </NavigationMenu.Link>
                        </NavigationMenu.Item>
                      ))}
                    </ul>
                  </Collapsible.Content>
                </Collapsible.Root>
              </li>
            ))}
          </NavigationMenu.List>
        </NavigationMenu.Root>
      </div>
    </>
  )
}

const Navigation = () => <NavigationLayout items={items} />

export default Navigation
