import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'

import type { AddGradesInAssignmentPayload } from '../constants/assignment-payload'

const arrayErrors: FieldErrorsMapper<AddGradesInAssignmentPayload> = {}

const generateArrayErrors = (data: AddGradesInAssignmentPayload) => {
  data.items.forEach((_, index) => {
    arrayErrors[`items.${index}.mark_id`] = `items.${index}.markId`
  })
}

const fieldErrorsMapper =
  (): FieldErrorsMapper<AddGradesInAssignmentPayload> => ({
    ...arrayErrors,
    non_field_error: 'root'
  })

const useAddGradesInAssignments = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: AddGradesInAssignmentPayload) => {
      generateArrayErrors(data)

      const payload = {
        items: data.items.map(item => ({
          id: item.id,
          mark_id: item.markId || null
        }))
      }

      return httpService.panel.panelGradesGradeItemsBulkUpdate(payload)
    },
    onSuccess: () => options?.onSuccess?.(),
    fieldErrorsMapper
  })

export default useAddGradesInAssignments
