import clsx from 'clsx'

import styles from './Tag.module.scss'

export type TagColor = 'blue' | 'green' | 'red' | 'orange' | 'gray' | 'violet'
export type TagVariant = 'default' | 'on-blue' | 'user-type'

type TagProps = {
  color?: TagColor
  variant?: TagVariant
  dataTestId?: string
  className?: string
  label: string
  prefix?: React.ReactNode
}

const Tag = (props: TagProps) => {
  const { color = 'blue', variant = 'default' } = props

  return (
    <span
      className={clsx(
        styles.tag,
        styles[color],
        styles[variant],
        props.className
      )}
      data-test-id={props.dataTestId}
    >
      {variant !== 'user-type' ? (
        props.prefix ? (
          props.prefix
        ) : (
          <div className={styles.dot} />
        )
      ) : null}
      <span className={styles.label}>{props.label}</span>
    </span>
  )
}

export default Tag
